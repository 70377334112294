import './LetterBox.scss';
import { useState } from 'react';
import { Letter } from '../models/letter';
import {colorGroup, colorsRef} from '../constants/lettersParts';


function LetterBox(props:any) {

  //let emptyPartArray : Part[] = partsRef.map((p)=>{return {code:p,count:0,color:}});
  const letter : Letter = props?.letter ;
    
  
  const changeColor = (group:number,code:number)=>{
    switch(group){
      case 1:
        letter.color1 = code;
      break;
      case 2:
        letter.color2 = code;
      break;
      case 3:
        letter.color3 = code;
      break;
      default:
        letter.color1 = code;
      break;
    }

    props.handleChangeLetterItem(letter,props.index);
    return;
  }
  
  const colorGroup1 = colorsRef.filter((cr)=> colorGroup.a.indexOf(cr.code) >=0 );
  const colorGroup2 = colorsRef.filter((cr)=> colorGroup.b.indexOf(cr.code) >=0 );
  const colorGroup3 = colorsRef.filter((cr)=> colorGroup.c.indexOf(cr.code) >=0 );

  return (
    <div className='letter-box'>

      <div className='letter-preview'>
        <div className='brick-letter'>
        <div className={'br-bg2' + ' color-g2-'+letter.color2}></div>
          <div className={'br-bg1' + ' color-g1-'+letter.color1} ></div>
          
          <div className={'br-letter' + ' color-g3-l'+ letter.char + ' color-g3-'+letter.color3}></div>
        </div>
      </div>
      <div className='colors-selector'>
        <div className='color-selector group-1'>
          <div className='selected-color' style={{backgroundColor: colorsRef.find((cr)=> letter.color1 === cr.code )?.hex }}>
            <div className='panel'>
              {
                colorGroup1.map((c,i)=>
                  <div key={"g1"+i} className='color-box' data-tooltip={c.name} style={{backgroundColor: c.hex}} onClick={()=>{changeColor(1,c.code)}}>  </div>
                )
              }
            </div>
          </div>
        </div>
        <div className='color-selector group-2'>
          <div className='selected-color' style={{backgroundColor: colorsRef.find((cr)=> letter.color2 === cr.code )?.hex }}>
            <div className='panel'>
              {
                colorGroup2.map((c,i)=>
                  <div key={"g2"+i} className='color-box' data-tooltip={c.name} style={{backgroundColor: c.hex}} onClick={()=>{changeColor(2,c.code)}}>  </div>
                )
              }
            </div>
          </div>
        </div>
        <div className='color-selector group-3'>
          <div className='selected-color' style={{backgroundColor: colorsRef.find((cr)=> letter.color3 === cr.code )?.hex }}>
            <div className='panel'>
              {
                colorGroup3.map((c,i)=>
                  <div key={"g3"+i} className='color-box' data-tooltip={c.name} style={{backgroundColor: c.hex}} onClick={()=>{changeColor(3,c.code)}}>  </div>
                )
              }
            </div>
          </div>
        </div>
      </div>
      
     </div>
  );
}

export default LetterBox;
