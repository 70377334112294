import { useEffect, useRef, useState } from 'react';
import './Hypotenuse.scss';

import { Slider, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import { useTranslation } from "react-i18next";

function Hypotenuse() {

  const { t, i18n } = useTranslation();
  const [pointsHipo, setPointsHipo] = useState([] as any[]);
  const [beams, setBeams] = useState([] as any[]);


  const [angle, setangle] = useState(46);
  const [width, setwidth] = useState(50);
  const [zoom, setzoom] = useState(100);

  const [tolerance, settolerance] = useState(0.08);

  const [overx, setoverx] = useState(null as null | number);
  const [overy, setovery] = useState(null as null | number);

  const [minMaxValue, setMinMaxValue] = useState<number[]>([0, 180]);



  const zoomto = function () {
      //$("#base").animate({ 'zoom':  zoom/100 }, 400);
};
const beamw = 24;
const beamh = 24;
let beamsTemp:any[] = [];
let rendered = false;

useEffect(() =>{
  drawtriangle()
},[angle,width,minMaxValue,tolerance]);


const gettrelices = function () {
    
  const calcules :any[] = [];
   var pointsvalid:any = {};
     for(var k = 0;k<width;k++)
      {
          pointsvalid["a"+k] = false;
      }
     
      for(var i = 0;i<width;i++)
      {
          calcules.push({cols:[]})
          for(var j = 0;j<width;j++)
          { 
            var il = i+1;
            var jl = j+1;
            var calc = Math.sqrt((il*il) + (jl*jl) - (2*il*jl * Math.cos(angle * (Math.PI / 180))));
            var tol = tolerance / 8; 
            var dif = calc % 1 <= tol || calc % 1 >= (1-tol) ;
            var marge = calc % 1 <= tol?calc % 1:calc % 1 >= (1-tol)?1- calc % 1:0;
             if(dif )
             {
              pointsvalid["a"+j] =true;
                 if(jl <= width)
                 {
                    const beam = addbeam({x:il,y:0},findNewPoint(0,0,angle ,jl),true,il,jl);
                    if(beam)
                    {
                      beamsTemp.push(beam );
                      
                    }
                    
                 }
                calcules[i].cols.push({x:il,y:jl,value:calc,marge:dif})
             }
          }
      }
      
      let hiposTemp = [];
      for(var l = 0;l<width;l++)
      {
          if( pointsvalid["a"+l] )
               {
                    const point1 = {x:l+1,y:0};
                    const point2 =findNewPoint(0,0,angle ,l+1);
                    hiposTemp.push({value:l+2,marginTop:point1.y *beamh ,marginLeft:point1.x *beamw,point:point1})
                    hiposTemp.push({value:l+2,marginTop:point2.y *beamh ,marginLeft:point2.x *beamw,point:point2})
               }
      }
      setPointsHipo(hiposTemp)
      setBeams(beamsTemp)
     
  };
  
  const drawtriangle = function () {
      // $("#base").empty();
      beamsTemp=[];

      beamsTemp.push(addbeam({x:0,y:0},{x: width,y:0},false,0,1));
      beamsTemp.push(addbeam({x:0,y:0},findNewPoint(0,0,angle,width),false,0,1));
      gettrelices();
      // $('[data-toggle="popover"]').popover()
  };
  
 

  var addbeam = function (pointa:any,pointb:any,textvisible:any,il:any,jl:any) {
       var nangle =  angletwopoints(pointa, pointb);
       var anglerelation = nangle;
      if(pointa.x > pointb.x)
      {
              nangle += 180;
              anglerelation = nangle - angle ;
      }
      if(anglerelation > minMaxValue[0] && anglerelation < minMaxValue[1] || !textvisible)
      {

      var distance = lineDistance(pointa, pointb);

      var classNamew = textvisible?"w_x" + Math.round(pointa.x*100) +"y"+ Math.round(pointa.y*100):"";
      var classNameh = textvisible?"h_x" + Math.round(pointb.x*100) +"y"+ Math.round(pointb.y*100):"";
      var textdistance = textvisible?Math.round(distance +1):"";
      var beamclassName = il===jl?"beam_good":"beam_bad";
      var beamclass = il===jl?"beam_good":"beam_bad";
      return {pointa,beamclass,pointb,textdistance,beamclassName,dist:Math.round(distance +1),pa:il+1,pb:jl+1,angle:nangle,width: (distance * beamw) - beamw,css:{'marginLeft': pointa.x * beamw,'marginTop': pointa.y * beamh,height: beamh, width: (distance * beamw) + beamw}}

     
      }
      return null;
               
  };

  var angletwopoints = function (point1:any, point2:any)
  {
      return Math.atan((point1.y - point2.y) / (point1.x - point2.x)) * 180 / Math.PI;
  }
  var  lineDistance = function(point1:any, point2:any)
  {
      var xs = 0;
      var ys = 0;
      xs = point2.x - point1.x;
      xs = xs * xs;
      ys = point2.y - point1.y;
      ys = ys * ys;
      return Math.sqrt(xs + ys);
  }
   var findNewPoint = function(x:any, y:any, angle:any, distance:any) {
      var result:any = {};
      result.x = Math.cos(angle * Math.PI / 180) * distance + x;
      result.y =Math.sin(angle * Math.PI / 180) * distance + y;
      return result;
  }

  const handleMinMax = (event: Event, newValue: number | number[]) => {
    let n1 = newValue as number[];
 
     setMinMaxValue(n1);
   };
  
  const setSlideValueA = (event:any)=>{
    setangle(event.target.value);
    
  }
  const setSlideValueW = (event:any)=>{
     
    setwidth(event.target.value);
   
  }
  const setSlideValueZ = (event:any)=>{
    setzoom(event.target.value);
    

  }
 
  const setSlideValueT = (event:any)=>{
    settolerance(event.target.value);

   
  }
  const handlePointOver = (point:any)=>{
      if(point)
      {
        setoverx(point.x)
        setovery(point.y)
      }
      else{
        setoverx(null)
        setovery(null)
      }
      console.log(point)
  }

  
  const getClassBeam = (pointa:any,pointb:any,x:any,y:any)=>{
    //beamfocused").removeClass("beamunfocused
    
    if(x === null)
    {
     return '';
    }
    else if((pointa.x === overx && pointa.y === overy) || (pointb.x === overx && pointb.y === overy))
    {
      return ' beamfocused';
    }
    else{
      return ' beamunfocused';
    }
  }
  return (
    <div  className='spe-container-top-gutter'>
     
    <div >

    <div >
    <Box   sx={{display:"flex",columnGap:"2em",padding: '10px'}}>
      <Box sx={{flex:1}}>
        <Typography id="input-slider"  >
        {t("Angle")}   
        </Typography>
        <Slider aria-label="ANGLE" defaultValue={30} value={angle} onChange={(e)=>setSlideValueA(e)}
        valueLabelDisplay="auto" step={0.5}  min={4} max={90}/>
        </Box>
        <Box sx={{flex:1}}>
        <Typography id="input-slider"  >
        {t("Size")}
        </Typography>
        <Slider aria-label="Size" defaultValue={30} value={width} onChange={(e)=>setSlideValueW(e)}
        valueLabelDisplay="auto" step={1}  min={1} max={100}/>
        </Box>
        <Box sx={{flex:1}}>
        <Typography id="input-slider"  >
        {t("Min-Max Angle")} 
        </Typography>
        <Slider
        getAriaLabel={() => 'Min-Max Angle'}
        value={minMaxValue}
        onChange={handleMinMax}
        valueLabelDisplay="auto"
        min={0}
        max={180}
      />
        </Box>
        <Box sx={{flex:1}}>
        <Typography id="input-slider"  >
        {t("Zoom")} 
        </Typography>
        <Slider aria-label="Zoom" defaultValue={30} value={zoom} onChange={(e)=>setSlideValueZ(e)}
        valueLabelDisplay="auto" step={1}  min={50} max={100}/>
        </Box>
        <Box sx={{flex:1}}>
        <Typography id="input-slider"  >
        {t("Tolerance")}
        </Typography>
        <Slider aria-label="Tolerance" defaultValue={30} value={tolerance} onChange={(e)=>setSlideValueT(e)}
        valueLabelDisplay="auto" step={0.01}  min={0.01} max={0.08}/>
        </Box>
      </Box>
         
       
    </div>
    
 </div> 

      <div className="containerbase">
            <div id="base" style={{zoom: (zoom / 100)}}>
            {pointsHipo.map((point,i)=>
            <div key={'point__'+i} onMouseOver={()=>handlePointOver(point.point)} onMouseOut={()=>handlePointOver(null)} className={'point_hipo_angle '} style={{marginTop:point.marginTop,marginLeft:point.marginLeft}}>
              {point.value}
              
            </div>
            )}
            {beams.map((a,i)=>

              <div key={'beam__'+i} className={'beam '+ a.beamclass + getClassBeam(a.pointa,a.pointb,overx,overy) } style={{...a.css,...{rotate:a.angle+'deg'}}}>
                <div className="beam_start"></div>
                <div className="beam_middle" style={{width:a.width}}>{a.textdistance}</div>
                <div className="beam_stop"></div>
                </div>

              )}
            </div>
        </div>
       
    </div>
    
  );
}

export default Hypotenuse;
