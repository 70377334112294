import './LettersPresentation.scss';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { Link as RouterLink } from "react-router-dom";
import { useTranslation } from "react-i18next";

function LettersPresentation() {
  const { t, i18n } = useTranslation();
  const  openLink =(link:string)=>{
    window.open(link,'_blank');
  }
 
  return (
   <div style={{paddingTop:"106px",width:'100%'}}>

       <Grid container  sx={{pt:2}} justifyContent="center">
          <Grid item xs={12} lg={5} >
            <img style={{ maxWidth: "100%" }} src='/images/letter/letter.jpg' ></img>
          </Grid>
          <Grid item xs={12}  lg={5} textAlign={{xs:"center",sm:"right"}} px={3}>
            <Typography variant="h5" color="#000" gutterBottom>
            {t("Recreates your name or any word with bricks,")}
              <br/>{t("in an elegant and studless way.")} 
            </Typography>
            <Typography variant="subtitle1" color="#000" gutterBottom>
            {t("Instructions for all letters  are available on Rebrickable")} 
             
            </Typography>
            <Box pt={2}>
              <Button variant="contained"  sx={{width:'300px'}} size="large" onClick={()=>openLink("https://rebrickable.com/mocs/MOC-144006/tkel86/letters/#details")}>
              {t("Get Instructions on Rebrickable")} 
              </Button>
            </Box>
            <Box pt={2} pb={4}>
              <Button variant="outlined" sx={{width:'300px',textAlign:'center'}}  size="large"  component={RouterLink} to="/lettersList">
              {t("Part List Generator")} 
              </Button>
            </Box>
          </Grid>

          <Grid item xs={12} pt={2} pb={4} style={{background:"#333"}}>

            <div className="spe-photobanner-container">
              <div className="spe-photobanner">
              <img src="/images/letter/ex1.jpg" ></img>
              <img src="/images/letter/ex2.jpg"></img>
              <img src="/images/letter/ex3.jpg" ></img>
              <img src="/images/letter/ex4.jpg"></img>
              <img src="/images/letter/ex5.jpg"></img>
              <img src="/images/letter/ex1.jpg" ></img>
              <img src="/images/letter/ex2.jpg"></img>
              <img src="/images/letter/ex3.jpg" ></img>
              <img src="/images/letter/ex4.jpg"></img>
              <img src="/images/letter/ex5.jpg"></img>
              </div>
            </div>
              
            </Grid> 
          <Grid style={{background:"#333",padding:"3em",minHeight:"100vh"}} item xs={12} >
          <Grid container justifyContent="center">
            <Grid  item xs={12} md={8} textAlign="center">
            <Typography variant="h4" color="#eee" pb={4}>
            {t("You can combine any letter")} 
                
            </Typography>
              <img style={{ width: "100%" }} src='/images/letter/all_letters.png' ></img>
            </Grid>
          </Grid>
           
          </Grid>
          <Grid item xs={6} p={2}>
         <Box className="spe-info-box spe-info-box-base-5">
          <div className="spe-skewed-container">
            <div className="spe-left spe-skewed">
              <div className="spe-left spe-unskewed">
                <Box>
                <Typography variant="h5" color="#fff" gutterBottom>
                {t("Part List Generator")} 
                
                   
                  </Typography>
                  <Typography variant="subtitle1" color="secondary" gutterBottom>
                  {t("Create Custom Letters, combining all the colors availables. And export the part list.")} 
                    
                  </Typography>
                  
                  <Button variant="outlined" color="info" sx={{mt:1}}
                  component={RouterLink} to="/lettersList">
                    {t("Go Now")} 
                    
                    
                  </Button>
                </Box>
              </div>
            </div>
            <div className="spe-right spe-skewed">
              <div className="spe-right spe-unskewed">
              <div className="spe-info-img-container">
                <div className="spe-info-img spe-bg-h5">
                </div>
              </div>
              </div>
            </div>
          </div>
        </Box>
         </Grid>
         <Grid item xs={6} p={2}>
         <Box className="spe-info-box spe-info-box-base-6">
          <div className="spe-skewed-container">
            <div className="spe-left spe-skewed">
              <div className="spe-left spe-unskewed">
                <Box>
                <Typography variant="h5" color="#fff" gutterBottom>
                {t("Building Instructions")}  
                  </Typography>
                  <Typography variant="subtitle1" color="secondary" gutterBottom>
                  {t("Purshase the instructions for all letters on Rebrickable")}  
                  </Typography>
                  <Button variant="outlined" color="info" sx={{mt:1}}  onClick={()=>openLink("https://rebrickable.com/mocs/MOC-144006/tkel86/letters/#details")}>
                  {t("Get it")}  
                    
                  </Button>
                </Box>
              </div>
            </div>
            <div className="spe-right spe-skewed">
              <div className="spe-right spe-unskewed">
              <div className="spe-info-img-container">
                <div className="spe-info-img spe-bg-h6">
                </div>
              </div>
              </div>
            </div>
          </div>
        </Box>
         </Grid>
         


        </Grid>
   </div>
  );
}

export default LettersPresentation;
