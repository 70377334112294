import { Link, Outlet } from 'react-router-dom';
import {useEffect, useState } from 'react';
import Header from './components/Header';
import './Layout.scss';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import Footer from './components/Footer';
import { useCookies } from 'react-cookie';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Switch from '@mui/material/Switch';

import Grid from '@mui/material/Grid';
import {  Typography } from '@mui/material';

//"https://policies.google.com/privacy?hl=pt-PT"


const theme = createTheme({

  palette: {
    background: {
      paper: "#fff",
    },
    text: {
      primary: "#05181f",
      secondary: "#415d66",
     
    },
    action: {
      active: "#001E3C",
    },
    primary: {
      main: "#3C8699",
    },
    info: {
      main: "#FFFFFF",
    },
    secondary: {
      main: "#ddd",
    },
  },
});

function Layout() {

  const [open, setOpen] = useState(false);
  
  const [cookies, setCookie, removeCookie] = useCookies(['cookieConsent']);

  useEffect(() =>{
  if(!cookies.cookieConsent)
  {
    setOpen(true);
  }
  },[cookies]);

  const handleClickAccept = () => {
    setCookie('cookieConsent',true,{ path: '/' });
    setOpen(false);
  };

  
  const handleClose = () => {
    setOpen(false);
  };


/*
  if( window.location.hostname != "localhost")
  {
    window.dataLayer = window.dataLayer || [];
      function gtag(){dataLayer.push(arguments);}
      gtag('js', new Date());
      gtag('config', 'G-3THFV4MJK8');
  }

 
ReactGA.initialize('UA-000000-01');
ReactGA.pageview(window.location.pathname + window.location.search);
  */
 
 
  return (
    <div >
    <ThemeProvider theme={theme}>
    <Header></Header>
    <div className='spe-container-shadow spe-container-fluid'>
    <div className='spe-container '>
      <Outlet />
    </div>
    <Footer></Footer>
    </div>
    
    <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
       
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
          <Grid container  spacing={2 } >
            <Grid item xs={12}>
              <Typography variant='subtitle2'  >
              This website uses cookies to enhance your experience while browsing the website. 
              Of these, cookies that are classed as necessary are stored in your browser as they are essential for the basic functionality of the website to work.
              </Typography>
            </Grid>
            <Grid item xs={8}>
              <Typography variant='subtitle1'  >
                Necessary
              </Typography>
              <Typography variant='subtitle2' color="#333" >
                Cookie Preference & Language. 
              </Typography>
            </Grid>
            <Grid item xs={4} sx={{textAlign:"right"}}>
              <Switch checked={true} disabled  />
            </Grid>
            
          </Grid>
        
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClickAccept} autoFocus>
            Ok
          </Button>
        </DialogActions>
      </Dialog>
      </ThemeProvider>
  </div>
  );
}

export default Layout;
