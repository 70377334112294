import { Link as RouterLink } from "react-router-dom";
import * as React from "react";
import "./Header.scss";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";

import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";

import Drawer from "@mui/material/Drawer";
import Collapse from "@mui/material/Collapse";

import List from "@mui/material/List";
import Divider from "@mui/material/Divider";

import ListItemButton from "@mui/material/ListItemButton";

import ListItemText from "@mui/material/ListItemText";

import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";

import ListSubheader from "@mui/material/ListSubheader";

import InstagramIcon from '@mui/icons-material/Instagram';
import InfoIcon from '@mui/icons-material/Info';
import ListItemIcon from  "@mui/material/ListItemIcon";
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import { useTranslation } from "react-i18next";

const legoPages = [
  { name: "Hypotenuse",desc:"Find possible liftarms connections", to: "/hypotenuse" },
  { name: "Angle Connections",desc:"Connections between 2 liftarms ", to: "/angles" },
  { name: "Gears",desc:"Combine multiples gears", to: "/gears" },
];


const aboutPages = [
  { name: "About Me",desc:"Find more about me", to: "/about" , icon: <InfoIcon  />},
  { name: "Support Me",desc:"Support this project", to: "/support", icon: <ThumbUpIcon /> }
];

function Header() {
  const { t, i18n } = useTranslation();
  const  openLink =(link:string)=>{
    window.open(link,'_blank');
  }

  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(
    null
  );
  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(
    null
  );
  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };
  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const [anchorElLego, setAnchorElLego] = React.useState<null | HTMLElement>(
    null
  );
  const handleOpenLegoMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElLego(event.currentTarget);
  };
  const handleCloseLegoMenu = () => {
    setAnchorElLego(null);
  };

  const [openDrower, setOpenDrower] = React.useState<boolean>(false);
  const toggleDrawer = (open: boolean) => (
    event: React.KeyboardEvent | React.MouseEvent
  ) => {
    if (
      event.type === "keydown" &&
      ((event as React.KeyboardEvent).key === "Tab" ||
        (event as React.KeyboardEvent).key === "Shift")
    ) {
      return;
    }

    setOpenDrower(open);
  };
  const [openLegoDrawer, setOpenLegoDrawer] = React.useState(false);

  const handleLegoDrawer = () => {
    setOpenLegoDrawer(!openLegoDrawer);
  };
 
  
  const drawerList = (
    <List
      sx={{ width: "100%", bgcolor: "background.paper" }}
      component='nav'
      aria-labelledby='nested-list-subheader'
      subheader={
        <ListSubheader  component='div'  id='nested-list-subheader'  sx={{ width: "100%", bgcolor: "text.secondary", color: "#fff" }} >
          {t("Menu")}  
        </ListSubheader>
      }
    >
      <ListItemButton onClick={handleLegoDrawer}>
        <ListItemText primary={t("Lego Tools")} />
        {openLegoDrawer ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>
      <Divider />
      <Collapse in={openLegoDrawer} timeout='auto' unmountOnExit>
        <List component='div' disablePadding>
          {legoPages.map((page) => (
            <ListItemButton  key={t(page.name)}  component={RouterLink} to={page.to} onClick={toggleDrawer(false)} sx={{ pl: 4 }}>
              <ListItemText primary={t(page.name)} secondary={t(page.desc)} />
            </ListItemButton>
          ))}
        </List>
        <Divider />
      </Collapse>
      <ListItemButton  component={RouterLink}
              to="/letters" onClick={toggleDrawer(false)}>
        <ListItemText primary={t("Lego Letters")} />
       
      </ListItemButton>
     
    </List>
  );

  return (
    <AppBar position='fixed' className='spe-header'>
      <Toolbar>
        <div className='spe-container-fluid'>
          <Box  sx={{ display: { xs: "none", md: "flex" }, alignItems: "center",  mr: 3, }}>
            <RouterLink to="/">
            <img style={{ maxHeight: "30px" }} src='/images/logos/logo_white.png' ></img>
             </RouterLink>
          </Box>
          <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" },alignItems: "center" }} >
            <IconButton
              size='large'
             
              aria-controls='menu-appbar'
              aria-haspopup='true'
              onClick={toggleDrawer(true)}
              color='inherit'
              sx={[ { mx: 1, color: "#ccc", display: "flex" }, { "&:hover": {  color: "#fff",  backgroundColor: "#ffffff11",}}]}>
              <MenuIcon />
            </IconButton>
          </Box>
          <Box sx={{  display: { xs: "flex", md: "none" }, mr: 1, flexGrow: 1, alignItems: "center" }} >
            <img  style={{ maxHeight: "30px" }}  src='/images/logos/logo_white.png' ></img>
          </Box>
          <Box sx={{ alignItems: "center", flexGrow: 1, display: { xs: "none", md: "flex" } }}>
            <Button
              variant='text'
              onClick={handleOpenLegoMenu}
              sx={[ { mx: 1, color: "#ccc", display: "block" }, { "&:hover": {  color: "#fff",  backgroundColor: "#ffffff11",}}]}>
              {t("Lego Tools")}
            </Button>
            <Menu id='menu-lego'
              anchorEl={anchorElLego}
              anchorOrigin={{  vertical: "bottom",  horizontal: "center"}}
              transformOrigin={{   vertical: "top",  horizontal: "center" }}
              open={Boolean(anchorElLego)}
              onClose={handleCloseLegoMenu}
              sx={{ display: { xs: "none", md: "flex" } }} >
              {legoPages.map((page) => (
                <MenuItem sx={{px:3,py:2}} key={page.name}component={RouterLink}
                  to={page.to} onClick={handleCloseLegoMenu} >
                  {t(page.name)}
                </MenuItem>
              ))}
            </Menu>
            <Button
              variant='text'
              component={RouterLink}
              to="/letters"
              sx={[ { mx: 1, color: "#ccc", display: "block" }, { "&:hover": {  color: "#fff",  backgroundColor: "#ffffff11",}}]}>
              {t("Lego Letters")}
            </Button>
          </Box>

          <Box sx={{ flexGrow: 0, alignItems: "center", display: "flex" }}>
            <Box   sx={{ display: { xs: "none", md: "flex" } }}>
          <Tooltip title={t('Instagram')}>
          <IconButton
              size='large'
              onClick={()=>openLink("https://www.instagram.com/alexis.d.s/")}
              color='inherit'
              sx={[ { mx: 1, color: "#ccc", display: "flex" }, { "&:hover": {  color: "#fff",  backgroundColor: "#ffffff11",}}]}>
              <InstagramIcon />
            </IconButton>
            </Tooltip>
            <Tooltip title={t('Flickr')}>
            <IconButton
              size='large'
              onClick={()=>openLink("https://www.flickr.com/people/tkel86/")}
              color='inherit'
              sx={[ { mx: 1, color: "#ccc", display: "flex" }, { "&:hover": {  color: "#fff",  backgroundColor: "#ffffff11",}}]}>
                <img  style={{ maxHeight: "25px" }} src='/images/logos/flickr.png' ></img>
            </IconButton>
           </Tooltip>
           <Tooltip title={t('Lego Ideas')}>
            <IconButton
              size='large'
              onClick={()=>openLink("https://ideas.lego.com/profile/0dae6e45-0e80-4de0-a8e7-dc6ac9e74348/entries?query=&sort=top")}
              color='inherit'
              sx={[ { mx: 1, color: "#ccc", display: "flex" }, { "&:hover": {  color: "#fff",  backgroundColor: "#ffffff11",}}]}>
              <img  style={{ maxHeight: "25px" }} src='/images/logos/ideas.png'></img>
            </IconButton>
           </Tooltip>
           </Box>
              <Button
                sx={[ { mx: 1, color: "#ccc", display: "block" }, { "&:hover": {  color: "#fff",  backgroundColor: "#ffffff11",}}]}
                onClick={handleOpenUserMenu}>
                 {t("About Me")}
              </Button>
            <Menu
              sx={{ mt: "45px" }}
              id='menu-appbar'
              anchorEl={anchorElUser}
              anchorOrigin={{ vertical: "top",  horizontal: "right", }}
              keepMounted
              transformOrigin={{  vertical: "top", horizontal: "right", }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              {aboutPages.map((page) => (
                <MenuItem key={page.name} component={RouterLink}  to={page.to} sx={{pl:3,pr:5,py:2}}>
                   <ListItemIcon> {page.icon}</ListItemIcon>
                  <Typography textAlign='center' onClick={handleCloseUserMenu}> {t(page.name)}</Typography>
                </MenuItem>
              ))}
                <Divider  sx={{ display: { xs: "block", md: "none" } }}/>
                <MenuItem  sx={{ display: { xs: "block", md: "none" } }}  onClick={()=>openLink("https://www.instagram.com/alexis.d.s/")}>
                  <Typography textAlign='center'>{t("Instagram")}</Typography>
                </MenuItem>
                <MenuItem  sx={{ display: { xs: "block", md: "none" } }}  onClick={()=>openLink("https://www.flickr.com/people/tkel86/")}>
                  <Typography textAlign='center'>{t("Flickr")}</Typography>
                </MenuItem>
                <MenuItem  sx={{ display: { xs: "block", md: "none" } }} onClick={()=>openLink("https://ideas.lego.com/profile/0dae6e45-0e80-4de0-a8e7-dc6ac9e74348/entries?query=&sort=top")}>
                  <Typography textAlign='center'>{t("Lego Ideas")}</Typography>
                </MenuItem>
            </Menu>
          </Box>
        </div>
      </Toolbar>

      <Drawer anchor='left' open={openDrower} onClose={toggleDrawer(false)}>
        <Box sx={{ width: 250 }} role='presentation'>
          {drawerList}
        </Box>
      </Drawer>
    </AppBar>
  );
}

export default Header;
