import "./about.scss";
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import { useTranslation } from "react-i18next";

export default function About() {

  const { t, i18n } = useTranslation();
  return ( 
    <Box sx={{flex:1,px:'2em',pt:'150px',pb:'4em',minHeight:'calc(100vh - 200px)'}} >
    <Grid container spacing={2} >
      <Grid item  xs={4}>
      <img style={{maxWidth:'100%',borderRadius:"6px"}} src='/images/profile_photo.jpg' ></img>
       
       
        </Grid>
        <Grid item xs={8}>
        <Typography variant="h6"  className="spe-about-me-text">
        {t("about me line 1")}
        {t("about me line 2")} <br/>
        {t("about me line 3")}
         
        </Typography>
        <Typography variant="h6" color="primary"  gutterBottom>
        <small style={{color:"#666"}}> {t("Name")}:</small> {t("Alexis")}<br/>
        </Typography>
        <Typography variant="h6" color="primary"  gutterBottom>
        <small style={{color:"#666"}}>{t("Nationality")}:</small>  {t("Portuguese")}<br/>
        </Typography>
        <Typography variant="h6" color="primary"  gutterBottom>
        <small style={{color:"#666"}}>{t("Birth Year")}:</small> 1986<br/>
        </Typography>
        <Typography variant="h6" color="primary"  gutterBottom>
        <small style={{color:"#666"}}>{t("Occupation")}:</small>{t("Developper/Web Designer")}<br/>
        </Typography>
        <Typography variant="h6" color="primary"  gutterBottom>
        <small style={{color:"#666"}}>{t("Hobbies")}:</small> {t("Photography, Painting, poterry, Diy of all kinds (electronic, carpentry ...)")}<br/>
        </Typography>
        <Typography variant="h6" color="primary"  gutterBottom>
        <small style={{color:"#666"}}>{t("Lego User Group")}:</small> Comunidade 0937<br/>
        </Typography>
        </Grid>
       
    </Grid>
    </Box>
  );
}
