import { useState, useEffect, useRef, Fragment } from "react";
import "./home.scss";
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { Link as RouterLink } from "react-router-dom";
import Chip from '@mui/material/Chip';
import { useTranslation } from "react-i18next";

export default function Home() {

  const { t, i18n } = useTranslation();
  return ( 
  <Box sx={{flex:1,px:'1em',pt:'100px',pb:'1em'}} >
    

    <Grid container  spacing={2 } >
      <Grid item xs={12}>
        <Box className="spe-welcome" sx={{display: { sm: "block", md: "flex" }}}>
        <Box sx={{flex:1,textAlign:{ xs: "center", md: "left" }}}>
          <img style={{maxWidth:'100%', maxHeight: "100px",marginTop:'20px' }} src='/images/logos/idealexis_reflect.png' ></img>
        </Box>
        <Box sx={{flex:1,textAlign:{ sm: "center", md: "right" }}}>
          <Typography variant="h5" color="#fff" gutterBottom>
          {t("Just a place to share some ideas, tools, stuff.")}
          </Typography>
          <Typography variant="subtitle1" color="#efefef" gutterBottom>
          {t("Hope you find something useful.")}
          </Typography>
         
        </Box>
      
        </Box>
      </Grid>
      <Grid item xs={12} md={6} lg={4} >
        <Box className="spe-info-box spe-info-box-base-1">
          <div className="spe-skewed-container">
            <div className="spe-left spe-skewed">
              <div className="spe-left spe-unskewed">
                <Box>
                
                <Typography variant="h5" color="#fff" gutterBottom>
                {t("Angle Connections")} 
              </Typography>
              <Chip label={t("Lego Tools")}   size="small" style={{background:"#ffffff77" }} />
              <Typography variant="subtitle1" color="secondary" gutterBottom pt={1}>
            
              {t("Find possible liftarms connections")} 
              </Typography>
              <Button variant="outlined" color="info" sx={{mt:1}}
              component={RouterLink}
              to="/angles">
               {t("Check it out")}   
              </Button>
              </Box>
              </div>
            </div>
            <div className="spe-right spe-skewed">
              <div className="spe-right spe-unskewed">
              <div className="spe-info-img-container">
                <div className="spe-info-img spe-bg-h1">

                </div>
              </div>
              </div>
            </div>
          </div>
        </Box>
      </Grid>
      <Grid item xs={12} md={6} lg={4} >
        <Box className="spe-info-box spe-info-box-base-2">
          <div className="spe-skewed-container">
            <div className="spe-left spe-skewed">
              <div className="spe-left spe-unskewed">
              <Box>
                <Typography variant="h5" color="#fff" gutterBottom>
                {t("Gears")}   
              </Typography>
              <Chip label={t("Lego Tools")}    size="small" style={{background:"#ffffff77" }} />
              <Typography variant="subtitle1" color="secondary" gutterBottom pt={1}>
              {t("Combine multiples gears to get the ratio and speed")}
              </Typography>
              <Button variant="contained" color="info" sx={{mt:1}}
              component={RouterLink}
              to="/gears">
               {t("Open")}   
              </Button>
              </Box>
                
              </div>
            </div>
            <div className="spe-right spe-skewed">
              <div className="spe-right spe-unskewed">
              <div className="spe-info-img-container">
                <div className="spe-info-img spe-bg-h2">

                </div>
              </div>
              </div>
            </div>
          </div>
        </Box>
      </Grid>
      <Grid item xs={12} md={6} lg={4} >
        <Box className="spe-info-box spe-info-box-base-3">
          <div className="spe-skewed-container">
            <div className="spe-left spe-skewed">
              <div className="spe-left spe-unskewed">
              <Box>
                <Typography variant="h5" color="#222" gutterBottom>
                {t("Hypotenuse")}   
              </Typography>
              <Chip label={t("Lego Tools")}   size="small" style={{background:"#ffffff77" }} />
              <Typography variant="subtitle1" color="#444" gutterBottom pt={1}>
              {t("Find possible lego liftarms connections to a stud grid")}
              </Typography>
              <Button variant="contained" color="info" sx={{mt:1}}
              component={RouterLink}
              to="/hypotenuse">
              
               {t("Let's go")}
              </Button>
              </Box>
              </div>
            </div>
            <div className="spe-right spe-skewed">
              <div className="spe-right spe-unskewed">
              <div className="spe-info-img-container">
                <div className="spe-info-img spe-bg-h3">

                </div>
              </div>
              </div>
            </div>
          </div>
        </Box>
      </Grid>


      <Grid item xs={12} >
        <Box className="spe-info-box spe-info-box-base-4">
          <div className="spe-skewed-container">
            <div className="spe-left spe-skewed">
              <div className="spe-left spe-unskewed">
              <Box>
                <Typography variant="h5" color="#fff" gutterBottom>
                {t("Lego Letters")}
              </Typography>
              <Typography variant="subtitle1" color="secondary" gutterBottom>
              {t("Recreates your name or any word with bricks,")}
              <br/> {t("in an elegant and studless way.")}
              </Typography>
              <Button variant="outlined" color="info" sx={{mt:1}}
              component={RouterLink}
              to="/letters">
                {t("Create Your own")} 
              </Button>
              </Box>
              </div>
            </div>
            <div className="spe-right spe-skewed">
              <div className="spe-right spe-unskewed">
              <div className="spe-info-img-container">
                <div className="spe-info-img spe-bg-h4">

                </div>
              </div>
              </div>
            </div>
          </div>
        </Box>
      </Grid>

    </Grid>
    </Box>

  );
}
