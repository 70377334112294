
import { BrowserRouter as Router, Route, Routes ,Navigate} from 'react-router-dom';
import './App.scss';
import Layout from './Layout';
import Cv from './pages/cv';
import  './i18n';
import Triangles from './pages/Triangles';
import Hypotenuse from './pages/Hypotenuse';
import Gears from './pages/Gears';
import Letters from './pages/Letters';
import Home from './pages/home';
import LettersPresentation from './pages/LettersPresentation';
import ScrollTop from "./scrollTop";
import Contacts from './pages/contacts';
import Support from './pages/support';
import About from './pages/about';

import Mosaic from './pages/Mosaic';


function App() {
  return (
    <Router>
      <ScrollTop />
    <Routes>
      <Route  path='/' element={< Layout />}>
          <Route  path='/'   element={<Home />} />
          <Route  path='/letters'   element={<LettersPresentation />} />
          <Route  path='/mosaic'   element={<Mosaic />} />
          <Route  path='/hypotenuse'  element={<Triangles />} />
          <Route  path='/angles'  element={<Hypotenuse />} />
          <Route  path='/gears'  element={<Gears />} />
          <Route  path='/lettersList'  element={<Letters />} />
          <Route  path='/about'  element={<About />} />
          <Route  path='/support'  element={<Support />} />
          <Route  path='/contacts'  element={<Contacts />} />
        </Route>
        <Route  path='/cv' element={< Cv />}></Route>
    </Routes>
  </Router>
  
  );
}
/*

       
*/
export default App;
