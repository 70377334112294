import { useState, useEffect, useRef, Fragment } from "react";
import "./contacts.scss";
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { Link as RouterLink } from "react-router-dom";
import Chip from '@mui/material/Chip';

export default function Contacts() {


  return ( 
    <Box sx={{flex:1,px:'1em',pt:'100px',pb:'1em'}} >
    
    </Box>
  );
}
