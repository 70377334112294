import { useEffect, useRef, useState } from 'react';
import './Triangles.scss';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import Slider from '@mui/material/Slider';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';

function Triangles() {



  const [beamOptions, setbeamOptions] = useState({angle:0,class:"",tooltipVisible:false,tooltiptop:0,tooltipleft:0,tooltiptext:{x:"",y:"",h:"",a:"",t:""},height:0, width:0,middlewidth:0});
  const [beam_scale_x, setbeam_scale_x] = useState(0);
  const [beam_scale_y, setbeam_scale_y] = useState(0);
  const [beams_scale_x, setbeams_scale_x] = useState([]);
  const [beams_scale_y, setbeams_scale_y] = useState([]);
  const [points_hipos, setpoints_hipos] = useState([] as any[]);
  
  const [onoffMargeimit, setonoffMargeimit] = useState(0.08);
 const handleMargeimitChange = (event: Event, newValue: any ) => {
  setonoffMargeimit(newValue);
  
};


const [onoffswitch, setonoffswitch] = useState(true as boolean);
const  handleSwitchChange =(event:  React.MouseEvent<HTMLElement>, newValue: any )=>{
   if (newValue !== null) {
    setonoffswitch(newValue);
  }
}

  const [onoffHalfPoints, setonoffHalfPoints] = useState(true as boolean);
  const  handleHalfPointsChange =()=>{
    setonoffHalfPoints(!onoffHalfPoints);
 }

  const beanBase : any = useRef();
  const triangleMain : any = useRef();
  const [bstepwidth, setbstepwidth] = useState(0);
 

 useEffect(() =>{
    if(!beams_scale_x.length){
      setbstepwidth((triangleMain?.current?.parentNode.offsetWidth-62)/ 24) ;
     
    }
    
 });

 useEffect(() =>{
  setScales();
},[onoffMargeimit,onoffHalfPoints]);



  var beam_base_offset;
  var step = 24;
  var bstepheight =100;

  //var bstepwidth = 40 ;// Math.floor(($("#triangle_main").parent().innerWidth() -30) / 24);

  
    window.onresize = ((e)=>{
      setbstepwidth((triangleMain?.current?.parentNode?.offsetWidth-62)/ 24) ;
      
    });
 /*
     $("#beam_container").css({width: step * bstepwidth + 30});
     $("#beam_scale_x").css({width: step * bstepwidth  });
     $("#beam_scale_y").css({height: step * bstepheight  });
     */

    const beambasemousemove = (e:any) => {
     let beamOptionsTemp:any ={...beamOptions};
     let margelimitcalc = onoffMargeimit * 0.125;
     beam_base_offset = {top:beanBase.current.getBoundingClientRect().top + window.scrollY,left:beanBase.current.getBoundingClientRect().left + window.scrollX};
     var mouse_step = {x: Math.floor((e.pageX - beam_base_offset.left) /  (step/2)) * (step/2), y: Math.floor((e.pageY - beam_base_offset.top) /  (step/2)) *  (step/2)}
     var point_step = {x: (e.pageX - beam_base_offset.left) / step, y: (e.pageY - beam_base_offset.top) / step}
     var distance = lineDistance({x: 0, y: 0}, {x: mouse_step.x, y: mouse_step.y});
     var angle = angletwopoints({x: 0, y: 0}, {x: mouse_step.x, y: mouse_step.y});
     var text = "0";
     if (distance % 24 == 0)
     {
        beamOptionsTemp.class = "beam_good";
        beamOptionsTemp.tooltipVisible = true;
     }
     else if (distance % 12 == 0 && onoffHalfPoints)
     {
         
          beamOptionsTemp.class = "beam_middle";
          beamOptionsTemp.tooltipVisible = true;
     }
     else if ((onoffHalfPoints && (distance / 24) % 0.5 <= margelimitcalc) ||  (distance / 24) % 1 <= margelimitcalc)
     {
      beamOptionsTemp.class = "beam_middle";
      beamOptionsTemp.tooltipVisible = true;
             text = (8*((distance / 24) % 0.5)).toFixed(2);
         
     }
     else
     {
      beamOptionsTemp.class = "beam_bad";
      beamOptionsTemp.tooltipVisible = false;
       
     }
     beamOptionsTemp.tooltiptop = e.pageY;
     beamOptionsTemp.tooltipleft = e.pageX + 56- beam_base_offset.left;
     beamOptionsTemp.tooltiptext = {
      x:(Math.floor((point_step.x + 1) / 0.5) * 0.5).toFixed(2),
      y: (Math.floor((point_step.y + 1) / 0.5) * 0.5).toFixed(2),
      h: (Math.floor(((distance / 24 ) + 1 )/ 0.5) * 0.5).toFixed(2),
      a: (angle).toFixed(2),
      t: text
     }
     
     


     setbeam_scale_x(Math.floor(point_step.x*2))
     setbeam_scale_y(Math.floor(point_step.y*2))

    // $("#beam_scale_x div").removeclassName("scale_hover").eq(Math.floor(point_step.x*2)).addclassName("scale_hover")
    // $("#beam_scale_y div").removeclassName("scale_hover").eq(Math.floor(point_step.y*2)).addclassName("scale_hover")
    beamOptionsTemp.height = step;
    beamOptionsTemp.width = distance + step;
    beamOptionsTemp.middlewidth =  distance - step;
    beamOptionsTemp.angle = Math.abs(angle) + "deg";
    setbeamOptions(beamOptionsTemp);
    // $('#beam').css({height: step, width: distance + step}).find(".beam_middle").css({width: distance - step});
     //$("#beam").css({left:"-15px",top:"-15px"});
    // $("#beam").rotate({angle: Math.abs(angle)});
     //$("#beam").css({left:"0px",top:"-15px"});
 }
 

 const beambasemouseinout=(visible:boolean)=>{
  let beamOptionsTemp:any ={...beamOptions};
  beamOptionsTemp.tooltipVisible = false;
  setbeamOptions(beamOptionsTemp);
 }
 const setScales=()=>{
  let scalexbeamsTemp:any = [];
  let scaleybeamsTemp:any = [];
  let bstepwidthTemp =( (triangleMain?.current?.parentNode.offsetWidth-62)/ 24 )|| 0;
   for (let f=1;f<= bstepheight;f+=0.5){scaleybeamsTemp.push(f);}
   for ( let i = 1; i <= bstepwidthTemp; i+=0.5){ scalexbeamsTemp.push(i);}
   setbeams_scale_x(scalexbeamsTemp);
   setbeams_scale_y(scaleybeamsTemp);
let pointsTemps:any = [];
let margelimitcalc = onoffMargeimit * 0.125;
for (let i = 1; i <= bstepwidthTemp; i+=0.5)
 {
     for (let j =  1; j <= bstepheight; j+=0.5)
     {
         if(i==1 || j == 1)
         {
             
             
         }
         else if (lineDistance({x: 1, y: 1}, {x: i, y: j}) % 1 == 0)
         {
             var pointer:any = {x: i-1, y: j -1,top:step*(j -1) ,left:step*( i-1),class:"point_hipo point_hipo_good" };
             pointsTemps.push(pointer);
         }
         else if (lineDistance({x: 1, y: 1}, {x: i, y: j}) % 0.5 == 0 && onoffHalfPoints)
         {

          
            var pointer:any = {x: i-1, y: j -1,top:step*(j -1) ,left:step*( i-1),class:"point_hipo " };
            pointsTemps.push(pointer);
         }

         
         else if ((onoffHalfPoints && lineDistance({x: 1, y: 1}, {x: i, y: j}) % 0.5 <= margelimitcalc) || (lineDistance({x: 1, y: 1}, {x: i, y: j}) % 1 <= margelimitcalc) )
         {
              var pointer:any = {x: i-1, y: j -1,top:step*(j -1) ,left:step*( i-1),class:"point_hipo point_hipo_normal" };
              pointsTemps.push(pointer);
         }
         
     }
  }
  setpoints_hipos(pointsTemps);
 }



/*
 beam_base_offset = $('#beam_base').offset();
 var points_hipos = [];
 for (f=1;f<= bstepheight;f+=0.5)
 {
     $("#beam_scale_y").append("<div>"+f+"</div>")
 }
 
 
 */

const angletwopoints=(point1:any, point2:any)=>
{
 return Math.atan((point1.y - point2.y) / (point1.x - point2.x)) * 180 / Math.PI;
}
const lineDistance=(point1:any, point2:any)=>
{
  var xs = 0;
  var ys = 0;
  xs = point2.x - point1.x;
  xs = xs * xs;
  ys = point2.y - point1.y;
  ys = ys * ys;
  return Math.sqrt(xs + ys);
}
  //let emptyPartArray : Part[] = partsRef.map((p)=>{return {code:p,count:0,color:});
/*
<div className="onoffswitch" style={{width:'150px'}}>
        <input type="checkbox" onChange={handleSwitchChange} checked={onoffswitch} id="myonoffswitch"  className="onoffswitch-checkbox"   />
        <label className="onoffswitch-label" htmlFor="myonoffswitch" >
            <span className="onoffswitch-inner systemtechnic"></span>
            <span className="onoffswitch-switch "></span>
        </label>
    </div>
*/
  return (
    <div ref={triangleMain} style={{flex:1}} className='spe-container-top-gutter'>

    
<Box sx={{display:"flex",columnGap:"2em",padding: '10px'}}>
    <FormGroup>
    <ToggleButtonGroup
      color="primary"
      value={onoffswitch}
      exclusive
      onChange={handleSwitchChange}
      aria-label="Platform"
    >
      <ToggleButton value={true}>System</ToggleButton>
      <ToggleButton value={false}>Technic</ToggleButton>
    
    </ToggleButtonGroup>
     
    </FormGroup>
    <Box sx={{ width: 250 }}>
      <Typography id="input-slider"  >
        Tolerance
      </Typography>
      <Slider 
  valueLabelDisplay="auto" value={onoffMargeimit} onChange={handleMargeimitChange} step={0.01} marks min={0} max={0.08}  />
      </Box>
      
      <FormControlLabel control={<Switch checked={onoffHalfPoints} onChange={handleHalfPointsChange}   />} labelPlacement="start" label="Half Stud" />
      </Box>
      


   
    <div className={onoffswitch?'system':'technic'} id="main_container">

        <div id="beam_container"    style={{borderTop:'1px solid #059cce', float: 'left',overflow: 'hidden',width: step * bstepwidth + 30,    background:'#d0d8d9'}}>
          <div id="beam_scale_y" style={{ height: step * bstepheight}}>
          {beams_scale_y.map((a,i)=>
              <div  key={"scaley"+i} className={i===beam_scale_y?'scale_hover':''}>{a}</div>
              )}
            </div>
            <div id="beam_scale_x" style={{ width: step * bstepwidth}}>
            {beams_scale_x.map((a,i)=>
              <div key={"scalex"+i} className={i===beam_scale_x?'scale_hover':''}>{a}</div>
              )}
              
            </div>
           
            <div ref={beanBase} onMouseMove={(e)=>beambasemousemove(e)} onMouseLeave={(e)=>beambasemouseinout(false)} style={{width: step * bstepwidth ,height: step * bstepheight}} id="beam_base" >
             
                <div className={beamOptions.class} id="beam" style={{height:  beamOptions.height, width:  beamOptions.width,transform: "rotate("+beamOptions.angle+")"}}>
                    <div className="beam_start">
                    </div>
                    <div className="beam_middle" style={{ width:  beamOptions.middlewidth}}>
                    </div>
                    <div className="beam_stop">
                    </div>
                </div>
                {points_hipos.map((p)=>
                    <div key={p.top + "-" + p.left} className={p.class} style={{top:p.top ,left:p.left ,zIndex:(p.y === (beam_scale_x /2) &&p.x === (beam_scale_y/2))?1000:0 }}></div>
                )}
           
            </div>
        </div>
    </div>
    
    <div className="ui-corner-all" id="beams_tooltip" style={{top: beamOptions.tooltiptop,left: beamOptions.tooltipleft,display: beamOptions.tooltipVisible?'block':'none' }} >
      <div>
      <Grid container >
        <Grid item xs={6} className="tooltip_left">
        X
        </Grid>
        <Grid item xs={6} className="tooltip_right">
        {beamOptions.tooltiptext.x}
        </Grid>
        <Grid item xs={6} className="tooltip_left">
        Y
        </Grid>
        <Grid item xs={6} className="tooltip_right">
        {beamOptions.tooltiptext.y}
        </Grid>
        <Grid item xs={6} className="tooltip_left">
        Hipotenuse
        </Grid>
        <Grid item xs={6} className="tooltip_right">
        {beamOptions.tooltiptext.h}
        </Grid>
        <Grid item xs={6} className="tooltip_left">
        Angle
        </Grid>
        <Grid item xs={6} className="tooltip_right">
        {beamOptions.tooltiptext.a}º
        </Grid>
        <Grid item xs={6} className="tooltip_left">
        Tolerance
        </Grid>
        <Grid item xs={6} className="tooltip_right">
        {beamOptions.tooltiptext.t} mm
        </Grid>
      </Grid>
        
      </div>
    </div>
    </div>
  );
}

export default Triangles;
