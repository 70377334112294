import { Link as RouterLink } from "react-router-dom";
import * as React from "react";
import "./Footer.scss";
import { useTranslation } from "react-i18next";
import Box from "@mui/material/Box";
import Grid from '@mui/material/Grid';
import Typography from "@mui/material/Typography";
import Link from '@mui/material/Link';
import Button from "@mui/material/Button";
import EmailIcon from '@mui/icons-material/Email';

function Footer() {
  const { t, i18n } = useTranslation();

  return (
   <div className="spe-footer">
    <Grid container  >
      <Grid item xs={12} sm={4} sx={{p:2}}>
        <Typography variant="subtitle1" color="#ccc" gutterBottom>
        {t("Contact")} :
        </Typography>
        <Typography variant="subtitle2" color="#fff" gutterBottom>
        <Button variant="outlined" href="mailto:support@idealexis.pt" startIcon={<EmailIcon />}>
        support@idealexis.pt
        </Button>  
        </Typography>
      </Grid>
     
      <Grid item xs={12} sm={4} sx={{borderLeft:"2px solid #333",borderRight:"2px solid #333",p:2}}>
        <Typography variant="subtitle1" color="#fff" gutterBottom>
        {t("Footer support 1")}<br/>  {t("Footer support 2")}<br/>
           <Link  underline="hover" href="https://rebrickable.com/users/tkel86/mocs/">Rebrickable</Link>
        </Typography>
        
      </Grid>
      <Grid item xs={12} sm={4}  sx={{p:2}} >
        <Typography variant="subtitle1" color="#ccc" gutterBottom>
        {t("Interesting and inspiring")}
        </Typography>
        <Box  sx={{pb:1}}>
        <Link href="https://comunidade0937.com/forum/" underline="hover">Comunidade 0937</Link>
        </Box>
        <Box  sx={{pb:1}}>
        <Link href="https://oficina.blogs.sapo.pt/" underline="hover">Oficina dos Baixinhos</Link><br/>
        </Box>
        <Box  sx={{pb:1}}>
        <Link href=" http://playwellportugal.blogspot.com/" underline="hover">Play Well Portugal/</Link><br/>
        </Box>
        <Box  sx={{pb:1}}>
        <Link href=" https://www.youtube.com/@TiagoCatarino" underline="hover">Tiago Catarino</Link><br/>
        </Box>
        <Box  sx={{pb:1}}>
        <Link href="http://sariel.pl/" underline="hover">Sariel</Link><br/>
        </Box>
        <Box  sx={{pb:1}}>
        <Link href="https://www.nico71.fr/" underline="hover">Nico71</Link><br/>
        </Box>
        <Box  sx={{pb:1}}>
        <Link href="https://www.philohome.com/" underline="hover">Philohome</Link><br/>
        </Box>
       
        
        
        
        
       
       
      </Grid>
    </Grid>
      
        </div>
  );
}

export default Footer;
