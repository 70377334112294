import { useEffect, useRef, useState } from 'react';
import './Mosaic.scss';


function Mosaic() {
const input:any = useRef();
const canvas:any = useRef();

const [selectedImage, setSelectedImage] = useState(null as any);
const [dataImage, setDataImage] = useState([] as any[][]);
const [dimentionsCanvas, setDimentionsCanvas] = useState({height:600,width:800});
let files = []

const baseColors = [
  {
    "hex": "#FFFFFF",
    "name": "White",
  },
  {
    "hex": "#000000",
    "name": "Black",
  },
  {
    "hex": "#4DD2FF",
    "name": "blue",
  },
  {
    "hex": "#0040FF",
    "name": "blue",
  },
  {
    "hex": "#238C00",
    "name": "green",
  }
  
]

// from https://stackoverflow.com/a/5624139
function hexToRgb(hex:string) {
  var shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
  hex = hex.replace(shorthandRegex, function(m, r, g, b) {
    return r + r + g + g + b + b;
  });

  var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result ? {
    r: parseInt(result[1], 16),
    g: parseInt(result[2], 16),
    b: parseInt(result[3], 16)
  } : null;
}

// Distance between 2 colors (in RGB)
// https://stackoverflow.com/questions/23990802/find-nearest-color-from-a-colors-list
function distance(a:any, b:any) {
    return Math.sqrt(Math.pow(a.r - b.r, 2) + Math.pow(a.g - b.g, 2) + Math.pow(a.b - b.b, 2));
}

// return nearest color from array
function nearestColor(colorHex:string){
  var lowest = Number.POSITIVE_INFINITY;
  var tmp;
  let index = 0;
  baseColors.forEach( (el, i) => {
      tmp = distance(hexToRgb(colorHex), hexToRgb(el.hex))
      if (tmp < lowest) {
        lowest = tmp;
        index = i;
      };
      
  })
  return baseColors[index];
  
}
const changeInput = (event:any)=>{
  const ctx =canvas.current.getContext("2d");
  console.log(event.target.files[0]);
  const img = new Image();


  const max_width = 800;
  const max_height = 600;


 
  img.src = URL.createObjectURL(event.target.files[0]);
  
  img.addEventListener(
    "load",
    () => {
      var hRatio = max_width / img.width    ;
      var vRatio = max_height / img.height  ;
      var ratio  = Math.min ( hRatio, vRatio );
      
      ctx.drawImage(img, 0,0, img.width, img.height, 0,0,img.width*ratio, img.height*ratio);
      let data = [];
      for(let i=0;i<600;i+=10)
      {
        let line :any[] = [];
        for(let y=0;y<800;y+=20)
        {
          let offsetx = i%2?10:0;
          var p = ctx.getImageData(y, i, 10+offsetx, 5).data;
          var hex = "#" + ("000000" + rgbToHex(p[0], p[1], p[2])).slice(-6);
          let elem = {color:nearestColor(hex)};
          line.push(elem)
          console.log(elem)
        }
        data.push(line);
      }
      setDataImage(data);
    },
    false,
  );

  function rgbToHex(r:number, g:number, b:number) {
    if (r > 255 || g > 255 || b > 255)
        throw "Invalid color component";
    return ((r << 16) | (g << 8) | b).toString(16);
}
  //setSelectedImage(URL.createObjectURL(event.target.files[0]));
}
  return (
    <div className="padTop">
      <img src={selectedImage || ''} />
Mosaic
      <input ref={input} onChange={changeInput} type="file" accept="image/jpeg, image/png, image/jpg"></input>
      <div>
      <canvas width={dimentionsCanvas.width} height={dimentionsCanvas.height} ref={canvas}></canvas>
        </div>
        <div className='mosaic'>
        {dataImage.map((a,i)=>
          <div className='line' key={i}>
            {a.map((b : any,y)=>
              <div style={{background:b.color.hex}} className='col' key={i+' '+y}>

              </div>
            )}
          </div>
        )}
        </div>
    </div>
  );
}

export default Mosaic;
