import './Letters.scss';
import {letterPartsRef , partsRef, outerFrameParts,DefaultColor1,DefaultColor2,DefaultColor3} from '../constants/lettersParts';
import { useState, useRef ,useCallback} from 'react';
import { Letter } from '../models/letter';
import { Part } from '../models/part';
import LetterBox from '../components/LetterBox';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import beautify from "xml-beautifier";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Alert from '@mui/material/Alert';
import IconButton from '@mui/material/IconButton';
import Collapse from '@mui/material/Collapse';
import CloseIcon from '@mui/icons-material/Close';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { read, utils, writeFileXLSX } from 'xlsx';
import Box from "@mui/material/Box";
import { useTranslation } from "react-i18next";

function Letters() {

  const { t, i18n } = useTranslation();
  
  const [userText, setUserText] = useState('Type\nHere');
  const [userTextArray, setUserTextArray] = useState(  [
    {key:0,"char":"t","color1":1,"color2":11,"color3":1},{key:1,"char":"y","color1":1,"color2":11,"color3":1},{key:2,"char":"p","color1":1,"color2":11,"color3":1},{key:3,"char":"e","color1":1,"color2":11,"color3":1},{key:4,"char":"enter","color1":1,"color2":11,"color3":1},{key:5,"char":"h","color1":1,"color2":11,"color3":1},{key:6,"char":"e","color1":1,"color2":11,"color3":1},{key:7,"char":"r","color1":1,"color2":11,"color3":1},{key:8,"char":"e","color1":1,"color2":11,"color3":1}
] as Letter[]);
  const [partArray, setPartArray] = useState([
    {"code":"3005","color":1,"count":16},{"code":"3023","color":1,"count":35},{"code":"3024","color":1,"count":52},{"code":"3666","color":1,"count":12},{"code":"4162","color":1,"count":16},{"code":"48336","color":1,"count":8},{"code":"6005","color":1,"count":45},{"code":"6541","color":1,"count":32},{"code":"87079","color":1,"count":16},{"code":"87087","color":1,"count":32},{"code":"3005","color":11,"count":58},{"code":"3024","color":11,"count":47},{"code":"3460","color":11,"count":39},{"code":"3666","color":11,"count":16},{"code":"6091","color":11,"count":32},{"code":"87087","color":11,"count":32},{"code":"3008","color":11,"count":32},{"code":"3623","color":11,"count":11},{"code":"2431","color":1,"count":8},{"code":"3004","color":1,"count":27},{"code":"3022","color":1,"count":15},{"code":"3069b","color":1,"count":10},{"code":"3010","color":11,"count":8},{"code":"3023","color":11,"count":10},{"code":"3622","color":11,"count":7},{"code":"3710","color":11,"count":3},{"code":"11203","color":1,"count":1},{"code":"2420","color":1,"count":1},{"code":"6091","color":1,"count":13},{"code":"3004","color":11,"count":6},{"code":"3003","color":1,"count":6},{"code":"3710","color":1,"count":8},{"code":"63864","color":1,"count":3},{"code":"3623","color":1,"count":4}
] as Part[]);
  const [totalParts, setTotalParts] = useState(228 );

  const textAreaRef : any = useRef();
  const [xmlText, setXmlText] = useState("");
  const [openCopyAlert, setOpenCopyAlert] = useState(false);

  const [open, setOpen] = useState(false);
  const handleToggleDialog = (open:boolean) => {
    let xmltext = " <INVENTORY>\n";
    partArray.forEach(parts =>{
      xmltext+=  "<ITEM>\n"+
      "  <ITEMTYPE>P</ITEMTYPE>\n"+
      "  <ITEMID>"+parts.code+"</ITEMID>\n"+
      "  <COLOR>"+parts.color+"</COLOR>\n"+
      "  <QTYFILLED>"+parts.count+"</QTYFILLED>\n"+
      "</ITEM>\n";
    });

   
    xmltext+="</INVENTORY>";
    //setXmlText(beautify(xmltext));
    setXmlText(xmltext);
    setOpen(open);
  };

  const handleCopyXML = () => {
    textAreaRef?.current?.select();
    document.execCommand('copy');
    textAreaRef?.current?.setSelectionRange(0,0);
    setOpenCopyAlert(true);
  };
  


  const  handleUserTextChange =(text:string)=>{
    text = text.replace(/[^a-zA-Z\+\n ]/gmi, '');
    setUserText(text);
   console.log(text)
  }
  
  const exportFile = useCallback(() => {
    const ws = utils.json_to_sheet(partArray);
    const wb = utils.book_new();
    utils.book_append_sheet(wb, ws, "Data");
    writeFileXLSX(wb, userText+".xlsx");
  }, [partArray]);

  const getImageRef=(element:Part)=>{
  let partRefs : any = (partsRef as any)[element.code] as any;
    return "https://www.lego.com/cdn/product-assets/element.img.lod5photo.192x192/"+  (partRefs as any)[element.color?.toString() ||'']   +".jpg"
  };

  const getPartList=()=>{
    return partArray.map(element => element.count ?
      <div  className='part-box' key={element.code + element.color}>
      <img  src={getImageRef(element) }></img>
      <div >{element.count}</div>
      </div>:''
    )
  }

  const changeLetterItem=(id:number,item:Letter)=>{
    let letterArray = userTextArray.map((uta,i)=>{
      if(i === id)
      {
        uta = item;
      }
      return uta;
    })
    setUserTextArray(letterArray);
    updateParts(letterArray);
  }

  const handleKeyPress = (event:any) => {

    let letterArray = [...userTextArray];
    let modindex = event.target.selectionStart <= event.target.selectionEnd?event.target.selectionStart:event.target.selectionEnd;
    let modend = event.target.selectionStart <= event.target.selectionEnd?event.target.selectionEnd:event.target.selectionStart;
    if(event.ctrlKey ||( userTextArray.filter(x=>x.char !== "enter" ).length >= 50 && event.keyCode !== 8)){
      event.preventDefault();
      event.stopPropagation();
    }
    else if((event.keyCode >= 65 && event.keyCode <=  90) ||  event.keyCode == 187 ||  event.keyCode == 32 ||  event.keyCode == 13){
      letterArray.splice(modindex,modend-modindex,{key:modindex,char: event.key.toLowerCase(),color1:DefaultColor1,color2:DefaultColor2,color3:DefaultColor3})
    }
    else if(event.keyCode == 8){
      letterArray.splice(modindex!=modend?modindex:modindex-1,modend-modindex || 1)
    }
    else{
      event.preventDefault();
      event.stopPropagation();
    }
    setUserTextArray(letterArray);
    updateParts(letterArray);
    
  }

  const updateParts=(letterArray:Letter[])=>{
    let partArrayTemp : Part[] = [];
    let total = 0;

    letterArray.forEach((letter,i)=>{
      if(letter.char !== "enter" && letter.char !== " "  )
      {
      let l:any = (letterPartsRef as any)[letter.char];
      
      outerFrameParts.forEach((p : Part)=>{
        let partObject = partArrayTemp.find((pa)=>pa.code === p.code && letter.color1 == pa.color);
        if(partObject){
          partObject.count += p.count || 0;
        }
        else{
          partArrayTemp.push({code:p.code,color: letter.color1, count: p.count})
        }
        total++;
      });
      l.back.forEach((p : Part)=>{
        let partObject = partArrayTemp.find((pa)=>pa.code === p.code && letter.color2 == pa.color);
        if(partObject){
          partObject.count += p.count || 0;
        }
        else{
          partArrayTemp.push({code:p.code,color: letter.color2, count: p.count})
        }
        total++;
      });
      l.front.forEach((p : Part)=>{
        let partObject = partArrayTemp.find((pa)=>pa.code === p.code && letter.color3 == pa.color);
        if(partObject){
          partObject.count += p.count || 0;
        }
        else{
          partArrayTemp.push({code:p.code,color: letter.color3, count: p.count})
        }
        total++;
      });
    }
    });
    setPartArray(partArrayTemp);
    console.log(partArrayTemp);
    setTotalParts(total);
  }


  
  return (
   <div  className='spe-container-top-gutter'>

       <Grid container spacing={2} sx={{pt:2}}>
          <Grid item xs={8}>
        
          <div className='userTextArea'>
            <div className='textarea-label'><span>{t("Text").toUpperCase()}</span></div>
            <textarea value={userText}  onKeyDown={handleKeyPress}  onChange={(e) => handleUserTextChange(e.target.value)}/>
            <div className='textarea-counter'>{userTextArray.filter(x=>x.char !== "enter").length} / 50</div>
          </div>
          <div className='header-letter' >
          {t("Letters Configurations")}
          </div>
          <div className='sub-container'>
            {userTextArray.map((uta,i) => {
            if(uta.char === "enter"){
            return <br  key={i} />
            }
            else if(uta.char === " "){
              return <div key={i} className='space-letter'></div>
            }
            else{
              return  <LetterBox handleChangeLetterItem={changeLetterItem} key={i} index={i} letter={uta}></LetterBox>
            } 
          }
          )}
          </div>

          </Grid>
          <Grid item xs={4}>
          <div className='header-parts' >
            <Box>
            {t("Parts")} ( {totalParts} )
            </Box>
            <Box >
            <Button variant="contained" disabled={!partArray.length} size="small" sx={{mr:2}} onClick={exportFile}>
           { t("Export XLSX") }
            </Button>
            <Button variant="contained"  disabled={!partArray.length} size="small" onClick={()=>handleToggleDialog(true)}>
            {t("XML Text")} 
            </Button>
            </Box>
          </div>
          <div className='part-container '>
            {getPartList()}
          </div>
          </Grid>
        
        </Grid>


        <Dialog
        open={open}
        onClose={()=>handleToggleDialog(false)}
      >
        <DialogTitle id="alert-dialog-title">
          {"XML Exporter"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
         
          <textarea ref={textAreaRef} className='spe-textarea-reader' rows={10} cols={50}>
                  {xmlText}
        </textarea>

        <Collapse in={openCopyAlert}>
        <Alert
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                setOpenCopyAlert(false);
              }}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }
          sx={{ mb: 2 }}
        >
         {t("XML Copied !")}  
        </Alert>
      </Collapse>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
        <Button onClick={()=>handleCopyXML()} startIcon={< ContentCopyIcon/>}>
        {t("Copy")}
          </Button>
          <Button onClick={()=>handleToggleDialog(false)} startIcon={< CloseIcon/>}>{t("Close")}</Button>
         
        </DialogActions>
      </Dialog>

        </div>
  );
}

export default Letters;
