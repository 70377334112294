import { useState, useEffect, useRef, Fragment } from "react";
import "./support.scss";
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';

import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import { useTranslation } from "react-i18next";
export default function Support() {
  

  const  openLink =(link:string)=>{
    window.open(link,'_blank');
  }
  const { t, i18n } = useTranslation();

  return ( 
    <Box sx={{flex:1,px:'1em',pt:'130px',pb:'4em',minHeight: 'calc(100vh - 120px)'}} >
    <Grid container  spacing={2} justifyContent={'center'}>
    <Grid item xs={12} md={10} lg={8} sx={{textAlign:'center',mb:10}} >
    <ThumbUpIcon sx={{ fontSize: 60 }} className="spe-semibase-color"/>
    <Typography variant="h6"  sx={{borderTop:"1px solid #ccc",pt:3,mt:3}} gutterBottom>
    {t("support line 1")} 
     <span className="spe-semibase-color"> {t("following")} </span>
       {t("support line 2")} 
      <span className="spe-semibase-color">{t("sharing")}</span> 
      {t("support line 3")} 
       <span className="spe-semibase-color">{t("supporting")}</span> 
       {t("support line 4")} 
        <span className="spe-semibase-color">{t("buying")}</span>
        {t("support line 5")} 
         
                  </Typography>
        </Grid>
    </Grid>
    <Grid container  spacing={2}>
          <Grid item xs={12} sm={12} md={6}>
        <Box className="spe-info-box spe-info-box-base-7">
          <div className="spe-skewed-container">
            <div className="spe-left spe-skewed">
              <div className="spe-left spe-unskewed">
                <Box>
                
                <Typography variant="h5" color="#fff" gutterBottom>
                {t("Rebrickable Instructions")} 
              </Typography>
             
              <Typography variant="subtitle1" color="#fff" gutterBottom pt={1}>
              {t("You can support me and this website just by buying one of my instruction on rebrickable.")} 
              </Typography>
              <Button variant="contained" color="info" sx={{mt:1}}
              onClick={()=>openLink("https://rebrickable.com/users/tkel86/mocs/")} >
                {t("Buy an Instruction")} 
              </Button>
              </Box>
              </div>
            </div>
            <div className="spe-right spe-skewed">
              <div className="spe-right spe-unskewed">
              <div className="spe-info-img-container">
                <div className="spe-info-img spe-bg-h7">

                </div>
              </div>
              </div>
            </div>
          </div>
        </Box>
      </Grid>
      <Grid item xs={12}  sm={12} md={6} >
        <Box className="spe-info-box spe-info-box-base-8">
          <div className="spe-skewed-container">
            <div className="spe-left spe-skewed">
              <div className="spe-left spe-unskewed">
              <Box>
                <Typography variant="h5" color="#fff" gutterBottom>
                
                {t("Lego Ideas")} 
              </Typography>
              <Typography variant="subtitle1" color="secondary" gutterBottom pt={1}>
              {t("You can also support one of my project on Lego Ideas.")} 
              
              </Typography>
              <Button variant="contained" color="info" sx={{mt:1}}
              onClick={()=>openLink("https://ideas.lego.com/projects/5253cea1-64c9-4058-8fbe-ba84034fc5f0")}
             >
               {t("Support a project")} 
               
              </Button>
              </Box>
                
              </div>
            </div>
            <div className="spe-right spe-skewed">
              <div className="spe-right spe-unskewed">
              <div className="spe-info-img-container">
                <div className="spe-info-img spe-bg-h8">

                </div>
              </div>
              </div>
            </div>
          </div>
        </Box>
      </Grid>
     
    </Grid>
    </Box>

  );
}
