
const letterPartsRef = {
    a:{
        back:[{"code":"3005","count":6},{"code":"3024","count":6},{"code":"3460","count":4},{"code":"3666","count":2},{"code":"6091","count":4},{"code":"87087","count":4},{"code":"3008","count":6},{"code":"3023","count":1}],
        front:[{"code":"3004","count":6},{"code":"3022","count":2},{"code":"3023","count":2},{"code":"3069b","count":2},{"code":"3666","count":1},{"code":"6005","count":2},{"code":"6091","count":2}]
    },
    b:{
        back:[{"code":"3005","count":6},{"code":"3024","count":6},{"code":"3460","count":6},{"code":"3666","count":2},{"code":"6091","count":4},{"code":"87087","count":4},{"code":"3008","count":5},{"code":"3023","count":2}],
        front:[{"code":"2431","count":2},{"code":"3004","count":2},{"code":"3022","count":4},{"code":"3023","count":1},{"code":"3024","count":1},{"code":"3069b","count":1},{"code":"3623","count":3},{"code":"3666","count":2},{"code":"3710","count":2},{"code":"6005","count":2},{"code":"6091","count":2}]
    },
    c:{
        back:[{"code":"3005","count":9},{"code":"3024","count":5},{"code":"3460","count":6},{"code":"3666","count":2},{"code":"6091","count":4},{"code":"87087","count":4},{"code":"3004","count":1},{"code":"3008","count":2},{"code":"3010","count":2},{"code":"3023","count":3},{"code":"3622","count":1},{"code":"3623","count":1},{"code":"3710","count":2}],
        front:[{"code":"2431","count":2},{"code":"3003","count":2},{"code":"3004","count":3},{"code":"3022","count":2},{"code":"3024","count":2},{"code":"6005","count":2},{"code":"6091","count":2}]
    },
    d:{
        back:[{"code":"3005","count":9},{"code":"3024","count":9},{"code":"3460","count":10},{"code":"3666","count":2},{"code":"6091","count":4},{"code":"87087","count":4},{"code":"3004","count":1},{"code":"3008","count":7},{"code":"3010","count":2},{"code":"3023","count":5},{"code":"3622","count":1},{"code":"3623","count":1},{"code":"3710","count":2}],
        front:[{"code":"2431","count":2},{"code":"3003","count":2},{"code":"3004","count":3},{"code":"3022","count":2},{"code":"3024","count":2},{"code":"6005","count":2},{"code":"6091","count":2},{"code":"2431","count":2},{"code":"3004","count":2},{"code":"3022","count":4},{"code":"3023","count":5},{"code":"3024","count":1},{"code":"3623","count":3},{"code":"3710","count":2},{"code":"6005","count":2},{"code":"6091","count":2}]
    },
    e:{
        back:[{"code":"3005","count":9},{"code":"3024","count":5},{"code":"3460","count":5},{"code":"3666","count":2},{"code":"6091","count":4},{"code":"87087","count":4},{"code":"3004","count":2},{"code":"3008","count":2},{"code":"3010","count":2},{"code":"3023","count":2},{"code":"3622","count":2},{"code":"3623","count":2}],
        front:[{"code":"2431","count":2},{"code":"3003","count":2},{"code":"3004","count":2},{"code":"3022","count":2},{"code":"3023","count":1},{"code":"3024","count":4},{"code":"3710","count":2},{"code":"6005","count":2},{"code":"6091","count":2},{"code":"63864","count":1}]
    },
    f:{
        back:[{"code":"3005","count":7},{"code":"3024","count":7},{"code":"3460","count":4},{"code":"3666","count":2},{"code":"6091","count":4},{"code":"87087","count":4},{"code":"3004","count":1},{"code":"3008","count":4},{"code":"3010","count":1},{"code":"3622","count":2},{"code":"3623","count":1}],
        front:[{"code":"2431","count":1},{"code":"3003","count":1},{"code":"3004","count":3},{"code":"3022","count":1},{"code":"3023","count":2},{"code":"3024","count":3},{"code":"3710","count":2},{"code":"6005","count":1},{"code":"6091","count":1},{"code":"63864","count":1}]
    },
    g:{
        back:[{"code":"3005","count":7},{"code":"3024","count":8},{"code":"3460","count":5},{"code":"3666","count":2},{"code":"6091","count":4},{"code":"87087","count":4},{"code":"3004","count":1},{"code":"3008","count":3},{"code":"3010","count":1},{"code":"3023","count":4},{"code":"3622","count":1},{"code":"3623","count":3},{"code":"3710","count":1}],
        front:[{"code":"2431","count":1},{"code":"3003","count":1},{"code":"3004","count":2},{"code":"3022","count":4},{"code":"3023","count":1},{"code":"3024","count":2},{"code":"3069b","count":1},{"code":"3623","count":1},{"code":"6005","count":3},{"code":"6091","count":3},{"code":"63864","count":1}]
    },
    h:{
        back:[{"code":"3005","count":6},{"code":"3024","count":6},{"code":"3460","count":4},{"code":"3666","count":2},{"code":"6091","count":4},{"code":"87087","count":4},{"code":"3008","count":6},{"code":"3023","count":1}],
        front:[{"code":"3004","count":8},{"code":"3022","count":2},{"code":"3023","count":2},{"code":"3069b","count":3},{"code":"3666","count":2}]
    },
    i:{
        back:[{"code":"3005","count":6},{"code":"3024","count":4},{"code":"3460","count":6},{"code":"3666","count":2},{"code":"6091","count":4},{"code":"87087","count":4},{"code":"3008","count":5},{"code":"3623","count":4}],
        front:[{"code":"3004","count":3},{"code":"3022","count":2},{"code":"3023","count":2},{"code":"3069b","count":2}]
    },
    j:{
        back:[{"code":"3005","count":6},{"code":"3024","count":6},{"code":"3460","count":6},{"code":"3666","count":2},{"code":"6091","count":4},{"code":"87087","count":4},{"code":"3004","count":1},{"code":"3008","count":4},{"code":"3010","count":1},{"code":"3023","count":2},{"code":"3623","count":2}],
        front:[{"code":"3004","count":2},{"code":"3022","count":2},{"code":"3023","count":3},{"code":"3024","count":2},{"code":"3069b","count":3},{"code":"6005","count":1},{"code":"6091","count":1}]
    },
    k:{
        back:[{"code":"3005","count":6},{"code":"3024","count":6},{"code":"3460","count":3},{"code":"3666","count":2},{"code":"6091","count":4},{"code":"87087","count":4},{"code":"3008","count":6},{"code":"3623","count":2}],
        front:[{"code":"3004","count":4},{"code":"3022","count":4},{"code":"3023","count":2},{"code":"3024","count":2},{"code":"3069b","count":4},{"code":"3665","count":4},{"code":"54200","count":4}]
    },
    l:{
        back:[{"code":"3005","count":6},{"code":"3024","count":6},{"code":"3460","count":6},{"code":"3666","count":2},{"code":"6091","count":4},{"code":"87087","count":4},{"code":"3008","count":5},{"code":"3023","count":2},{"code":"3623","count":2}],
        front:[{"code":"3004","count":2},{"code":"3022","count":2},{"code":"3023","count":3},{"code":"3024","count":2},{"code":"3069b","count":2},{"code":"6005","count":1},{"code":"6091","count":1}]
    },
    m:{
        back:[{"code":"3005","count":6},{"code":"3024","count":4},{"code":"3460","count":5},{"code":"3666","count":2},{"code":"6091","count":4},{"code":"87087","count":4},{"code":"3008","count":5},{"code":"3010","count":1}],
        front:[{"code":"3003","count":2},{"code":"3004","count":7},{"code":"3023","count":3},{"code":"3070b","count":2},{"code":"3665","count":2},{"code":"6005","count":2},{"code":"6091","count":2},{"code":"92946","count":2}]
    },
    n:{
        back:[{"code":"3005","count":6},{"code":"3024","count":4},{"code":"3460","count":6},{"code":"3666","count":2},{"code":"6091","count":4},{"code":"87087","count":4},{"code":"3008","count":5},{"code":"3623","count":2},{"code":"3710","count":1}],
        front:[{"code":"3004","count":5},{"code":"3022","count":3},{"code":"3023","count":7},{"code":"3024","count":4},{"code":"3040","count":4},{"code":"3069b","count":1},{"code":"3070b","count":2},{"code":"6005","count":2},{"code":"6091","count":2},{"code":"92946","count":2}]
    },
    o:{
        back:[{"code":"3005","count":6},{"code":"3024","count":8},{"code":"3460","count":9},{"code":"3666","count":2},{"code":"6091","count":4},{"code":"87087","count":4},{"code":"3008","count":4},{"code":"3023","count":2}],
        front:[{"code":"3004","count":2},{"code":"3022","count":4},{"code":"3023","count":2},{"code":"3069b","count":2},{"code":"6005","count":4},{"code":"6091","count":4}]
    },
    p:{
        back:[{"code":"3005","count":6},{"code":"3024","count":6},{"code":"3460","count":6},{"code":"3666","count":2},{"code":"6091","count":4},{"code":"87087","count":4},{"code":"3008","count":5},{"code":"3023","count":1},{"code":"3623","count":2}],
        front:[{"code":"3004","count":3},{"code":"3022","count":3},{"code":"3023","count":2},{"code":"3024","count":2},{"code":"3069b","count":2},{"code":"6005","count":3},{"code":"6091","count":3}]
    },
    q:{
        back:[{"code":"3005","count":6},{"code":"3024","count":8},{"code":"3460","count":6},{"code":"3666","count":2},{"code":"6091","count":4},{"code":"87087","count":4},{"code":"3008","count":5},{"code":"3023","count":2}],
        front:[{"code":"2431","count":1},{"code":"3004","count":2},{"code":"3022","count":4},{"code":"3023","count":6},{"code":"3024","count":1},{"code":"3069b","count":1},{"code":"3665","count":1},{"code":"54200","count":1},{"code":"6005","count":3},{"code":"6091","count":3}]
    },
    r:{
        back:[{"code":"3005","count":6},{"code":"3024","count":6},{"code":"3460","count":4},{"code":"3666","count":2},{"code":"6091","count":4},{"code":"87087","count":4},{"code":"3008","count":6},{"code":"3710","count":1}],
        front:[{"code":"2431","count":1},{"code":"3004","count":3},{"code":"3022","count":1},{"code":"3023","count":6},{"code":"3024","count":2},{"code":"3069b","count":1},{"code":"3623","count":4},{"code":"3710","count":2},{"code":"6005","count":2},{"code":"6091","count":2}]
    },
    s:{
        back:[{"code":"3005","count":6},{"code":"3024","count":6},{"code":"3460","count":6},{"code":"3666","count":2},{"code":"6091","count":4},{"code":"87087","count":4},{"code":"3008","count":5},{"code":"3023","count":2},{"code":"3623","count":2}],
        front:[{"code":"2431","count":2},{"code":"3022","count":2},{"code":"3023","count":1},{"code":"3069b","count":2},{"code":"3623","count":4},{"code":"3666","count":2},{"code":"3710","count":2},{"code":"6005","count":2},{"code":"6091","count":2}]
    },
    t:{
        back:[{"code":"3005","count":6},{"code":"3024","count":4},{"code":"3460","count":4},{"code":"3666","count":2},{"code":"6091","count":4},{"code":"87087","count":4},{"code":"3008","count":6},{"code":"3623","count":2}],
        front:[{"code":"2431","count":1},{"code":"3004","count":4},{"code":"3022","count":1},{"code":"3023","count":3},{"code":"3069b","count":1},{"code":"3666","count":2}]
    },
    u:{
        back:[{"code":"3005","count":6},{"code":"3024","count":8},{"code":"3460","count":6},{"code":"3666","count":2},{"code":"6091","count":4},{"code":"87087","count":4},{"code":"3008","count":5},{"code":"3023","count":2}],
        front:[{"code":"3004","count":4},{"code":"3022","count":4},{"code":"3023","count":5},{"code":"3069b","count":3},{"code":"6005","count":2},{"code":"6091","count":2}]
    },
    v:{
        back:[{"code":"3005","count":6},{"code":"3024","count":8},{"code":"3460","count":6},{"code":"3666","count":2},{"code":"6091","count":4},{"code":"87087","count":4},{"code":"3008","count":4},{"code":"3023","count":4},{"code":"3623","count":4},{"code":"3710","count":2}],
        front:[{"code":"11203","count":1},{"code":"3004","count":2},{"code":"3022","count":3},{"code":"3023","count":3},{"code":"3069b","count":2},{"code":"54200","count":2},{"code":"60481","count":2},{"code":"92946","count":2}]
    },
    w:{
        back:[{"code":"3005","count":6},{"code":"3024","count":6},{"code":"3460","count":4},{"code":"3666","count":2},{"code":"6091","count":4},{"code":"87087","count":4},{"code":"3008","count":5},{"code":"3010","count":1}],
        front:[{"code":"3003","count":2},{"code":"3004","count":6},{"code":"3022","count":3},{"code":"3023","count":3},{"code":"3069b","count":3},{"code":"3070b","count":2},{"code":"3665","count":2},{"code":"6005","count":2},{"code":"6091","count":2},{"code":"92946","count":2}]
    },
    x:{
        back:[{"code":"3005","count":6},{"code":"3024","count":4},{"code":"3460","count":3},{"code":"3666","count":2},{"code":"6091","count":4},{"code":"87087","count":4},{"code":"3008","count":6},{"code":"3023","count":4}],
        front:[{"code":"3022","count":4},{"code":"3023","count":2},{"code":"3040","count":4},{"code":"3665","count":4},{"code":"54200","count":8}]
    },
    y:{
        back:[{"code":"3005","count":7},{"code":"3024","count":10},{"code":"3460","count":6},{"code":"3666","count":2},{"code":"6091","count":4},{"code":"87087","count":4},{"code":"3008","count":3},{"code":"3010","count":2},{"code":"3023","count":2},{"code":"3622","count":1},{"code":"3623","count":1},{"code":"3710","count":2}],
        front:[{"code":"11203","count":1},{"code":"2420","count":1},{"code":"3004","count":3},{"code":"3022","count":2},{"code":"3023","count":3},{"code":"3024","count":4},{"code":"3069b","count":3},{"code":"6005","count":2},{"code":"6091","count":2}]
    },
    z:{
        back:[{"code":"3005","count":6},{"code":"3024","count":8},{"code":"3460","count":3},{"code":"3666","count":2},{"code":"6091","count":4},{"code":"87087","count":4},{"code":"3008","count":6},{"code":"3023","count":2}],
        front:[{"code":"2431","count":2},{"code":"3022","count":4},{"code":"3023","count":2},{"code":"3024","count":4},{"code":"3040","count":3},{"code":"3069b","count":2},{"code":"3665","count":3},{"code":"3666","count":2},{"code":"3710","count":2}]
    },
    plus:{
        back:[{"code":"3005","count":6},{"code":"3024","count":4},{"code":"3460","count":4},{"code":"3666","count":2},{"code":"6091","count":4},{"code":"87087","count":4},{"code":"3008","count":6},{"code":"3623","count":2}],
        front:[{"code":"3004","count":3},{"code":"3022","count":1},{"code":"3023","count":2},{"code":"3069b","count":3},{"code":"3623","count":4},{"code":"3666","count":2}]
    }
    
};

const outerFrameParts = [{"code":"3005","count":2},{"code":"3023","count":2},{"code":"3024","count":4},{"code":"3666","count":1},{"code":"4162","count":2},{"code":"48336","count":1},{"code":"6005","count":4},{"code":"6541","count":4},{"code":"87079","count":2},{"code":"87087","count":4}];
// inner  3005, 3023, 3024,"3666","4162","48336","6005","6541","87079","87087"
// back ["3005","3024","3460","3666","6091","87087","3008","3023","3004","3010","3622","3623","3710"]
// front : ["3004","3022","3023","3069b","3666","6005","6091","2431","3024","3623","3710","3003","63864","3665","54200","3070b","92946","3040","11203","60481","2420"]
// a:[11,7,85,59,6,86,5,88,2,1,3],
// b:[11,7,153,63,85,47,59,39,6,152,86,34,157,5,88,48,2,1,3],
// c:[11,110,63,85,59,86,5,88,2,1,3]


const supportParts = [{"code":"2780","count":4},{"code":"62462","count":2}];
const legParts = [{"code":"30350c","count":1},{"code":"3068b","count":1},{"code":"3069b","count":1},{"code":"3832","count":1},{"code":"87079","count":1}];

const partsRef = {
    "11203":{11:6013867,110:6132853,63:6285466 ,85:6013082,59:6296962,86:6132886,5:6013868,88:6374056,2:6013081,1:6013866 ,3:6388485},
    "2420":{11:242026,110:6099714,63:4528482,85:4210635,59:4585751,86:4211353,5:242021,88:4211257,2:4114077,1:242001,3:242024},
    "2431":{11:243126,110:6072668,63:6103985,85:4211053,59:4164220,86:4211356,5:243121,88:4211194,2:4550324,1:243101,3:243124},
    "2780":{11:4121715},
    "3003":{11:300326,110:6295078,63:4296785,85:4211060,59:4539104,86:4211387,5:300321,88:4211210,2:4114306,1:300301,3:300324},
    "3004":{110:6003003,11:300426,7:300423,153:6004943,63:4249891,85:4211088,47:4621545,59:4539102,39:4121500,6:4107736,152:6022032,86:4211388,34:4164022,157:4623598,5:300421,88:4211149,48:4616581,2:4109995,1:300401,3:300424},
    "3005":{11:300526,7:300523,153:6225538,63:4255413,85:4211098,47:4492224,59:4541376,39:6213776,6:300528,152:4630751,86:4211389,34:4220634,157:4651903,5:300521,88:4211242,48:4521948,2:4113915,1:300501,3:300524},
    "3008":{11:300826,7:300823,153:6377006,63:6277303 ,85:4211099,47:1111111111111,59:4224519,39:6289133,6:4143953,152:6304892,86:4211392,34:4172974,157:6109970,5:300821,88:4263776,48:4521949,2:4159774,1:300801,3:300824},
    "3010":{11:301026 ,7:301023,153:6213272,63:4264569,85:4211103,47:4621542,59:6052777,39:6217660,6:4112838,152:6218162,86:4211394,34:4234716,157:6107188,5:301021,88:4211225,48:4153765,2:4113916,1:301001,3:301024},
    "3022":{11:302226,110:6003033,63:6037890,85:4114003,59:4163160,86:4211397,5:302221,88:4216695,2:4114084,1:302201,3:302224},
    "3023":{110:6028736,11:302326 ,7:302323,153:4653988,63:4177737,85:4211063,47:6057387,59:4162582,39:6213777,6:302328,152:6146701,86:4211398,34:4164037 ,157:4619512,5:302321,88:4211150,48:4655080,2:4113917,1:302301,3:302324},
    "3024":{110:6073040,11:302426,7:302423,153:6151664,63:4184108,85:4210719,47:6217797,59:4183901,39:6213778,6:302428,152:6058016,86:4211399,34:4261450,157:4619521,5:302421,88:4221744,48:6099189,2:4159553,1:302401,3:302424},
    "30350c":{85:4227517},
    "3040":{11:304026,110:6023157,63:4187118,85:4211135,59:4209500,86:4211614,5:4121934,88:4211199,2:4121972,1:4121932,3:4121965},
    "3068b":{85:4211055},
    "3069b":{11:306926,110:4622062,63:4205012,85:4211052,59:4165783,86:4211414,5:306921,88:4211151,2:4114026,1:306901,3:306924},
    "3070b":{11:307026,110:6065504,63:4631385,85:4210848,59:4187196,86:4211415,5:307021,88:4211288,2:4125253,1:307001,3:307024},
    "3460":{11:346026,7:346023,153:6210227,63:6250216,85:4210998,47:6173825,59:4162205,39:6259921,6:346028,152:6310373,86:4211425,34:4210212,157:4625022,5:346021,88:4216945,48:9999,2:4114324,1:346001,3:346024},
    "3622":{11:362226,7:362223,153:6154879,63:6233955,85:4211104,47:4253803,59:6264025,39:6213783,6:4109679,152:6172587,86:4211428,34:4166093,157:6109896,5:362221,88:4211220,48:4155360,2:4162465,1:362201,3:362224},
    "3623":{110:6073042,11:362326,7:362323,153:6153538,63:4177735,85:4211133,47:6404150,59:4164223,39:6213784,6:4107758,152:6146704,86:4211429,34:4210210,157:6136332,5:362321,88:4211152,48:6069257,2:4121921,1:362301,3:362324 },
    "3665":{11:366526,110:6136455,63:4274574,85:4211096,59:4590811,86:4211437,5:366521,88:4211222,2:4159196,1:366501,3:366524},
    "3666":{110:6020074,11:366626,7:366623,153:6151657,63:4177734,85:4211056,47:6097094,59:4164108,39:6291782,6:366628,152:6146702,86:4211438,34:4529160,157:4649745,5:366621,88:4221590,48:6099187,2:4124067,1:366601,3:366624},
    "3710":{110:6020073,11:371026,7:371023,153:6133728,63:4177739,85:4211001,47:6161222,59:4164219,39:6350418,6:371028,152:6141592,86:4211445,34:4187743,157:4619524,5:371021,88:4211190,48:4633698,2:4113233,1:371001,3:371024},
    "3832":{85:4210678},
    "4162":{11:416226,7:416223,85:4211008,59:4261701,6:4296081,86:4211481,5:416221,88:4211278,2:4219560,1:416201,3:4189400},
    "48336":{11:4225201,7:4514398,85:6146321,59:4259941,6:4521931,86:4244627,5:4226876,88:4234362,2:4217562,1:4222017,3:4501232},
    "54200":{11:4504382,110:6023173,63:4504374,85:4504378,59:4531415,86:4521921,5:4504379,88:4504376,2:4516055,1:4504369,3:4504381},
    "6005":{110:6035590,63:4529746,11:4618880,7:4644084,85:4618881,59:4629912,6:4644085,86:4625619,5:4631357,88:6299939,2:6055782,1:4629913,3:4652261},
    "60481":{11:4515373,110:6186637,63:6097429,85:6013658,59:4645101,86:4515374,5:4515371,88:4629736,2:4626200,1:4515370,3:6013498},
    "6091":{110:6023185,11:609126 ,7:609123,153:6151669,63:6115447,85:4210797,47:6056413 ,59:4539082,39:6213785,6:4129994,152:6097304,86:4211519,34:4212446,157:4625616,5:609121,88:6035547,48:6258321,2:4273526,1:609101,3:609124},
    "62462":{11:4526982},
    "63864":{11:4558170,110:6099725,63:6132566,85:4568734,59:4583299,86:4568734,5:4533742,88:6100769,2:6131896,1:4558168,3:4558172},
    "6541":{11:654126,7:4119014,85:4210639,59:6267414,6:4522678,86:4211535,5:654121,88:4620075,2:4179771,1:654101,3:654124},
    "87079":{11:4560182,7:4560180,85:4560184,59:6186003,6:4566179,86:4560183,5:4560179,88:4579690,2:4610209,1:4560178,3:6388490},
    "87087":{11:4558954,7:4583862,153:6004938,63:6224377,85:4558955,47:6217793,59:4651677,39:6213781,6:6385644,152:4619815,86:4558953,34:6073026,157:6125776,5:4558886,88:6062574,48:6009656,2:4579260,1:4558952,3:4624985},
    "92946":{11:4653087,110:6020106,63:6186683,85:4621917,59:6251611,86:6093859,5:4603263,88:6075287,2:4624988,1:6015912,3:6018491},
};
const colorGroup = {
    a:[11,7,85,59,6,86,5,88,2,1,3],
    b:[11,7,153,63,85,47,59,39,6,152,86,34,157,5,88,48,2,1,3],
    c:[11,110,63,85,59,86,5,88,2,1,3]
}


const colorsRef = [
    {code:11,name:"Black",hex:"#212121"},
    {code:110 ,name:"Bright Light Orange",hex:"#FFC700"} ,
    {code:7,name:"Blue",hex:"#0057A6"},
    {code:153,name:"Dark Azure",hex:"#009FE0"},
    {code:63,name:"Dark Blue",hex:"#243757"},
    {code:85,name:"Dark Bluish Gray",hex:"#595D60"},
    {code:47,name:"Dark Pink",hex:"#EF5BB3"},
    {code:59,name:"Dark Red",hex:"#6A0E15"},
    {code:39,name:"Dark Turquoise",hex:"#00A29F"},
    {code:6,name:"Green",hex:"#00923D"},
    {code:152 ,name:"Light Aqua",hex:"#CFEFEA"},
    {code:86,name:"Light Bluish Gray",hex:"#AFB5C7"},
    {code:34,name:"Lime",hex:"#C4E000"},
    {code:157,name:"Medium Lavender",hex:"#C689D9"},
    {code:5,name:"Red",hex:"#B30006"},
    {code:88,name:"Reddish Brown",hex:"#82422A"},
    {code:48,name:"Sand Green",hex:"#A2BFA3"},
    {code:2,name:"Tan",hex:"#EED9A4"},
    {code:1,name:"White",hex:"#ffffff"},
    {code:3,name:"Yellow",hex:"#FFE001"}
    

]
/*
11---Black
110 ---Bright Light Orange
7---Blue
153---Dark Azure
63---Dark Blue
85---Dark Bluish Gray
47---Dark Pink
59---Dark Red
39---Dark Turquoise
6---Green
152 ---Light Aqua
86---Light Bluish Gray
34---Lime
157---Medium Lavender
5---Red
88---Reddish Brown
48---Sand Green
2---Tan
1---White
3---Yellow
*/

const DefaultColor1 = 1;
const DefaultColor2 = 11;
const DefaultColor3 = 1;


export {letterPartsRef, partsRef,outerFrameParts, DefaultColor1, DefaultColor2, DefaultColor3,colorGroup, colorsRef};
