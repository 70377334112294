import { FormControl, InputLabel, MenuItem, Popover, Select, SelectChangeEvent } from '@mui/material';
import React from 'react';
import { useEffect, useRef, useState } from 'react';
import './Gears.scss';
import Box from '@mui/material/Box';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import FormGroup from '@mui/material/FormGroup';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import IconButton from '@mui/material/IconButton';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import { useTranslation } from "react-i18next";

function Gears() {
  const { t, i18n } = useTranslation();

 const gearcollection = [
            
  {name:"Gear 8",count:8,class:'gear-8',radius:14},
  {name:"Gear 12",count:12,class:'gear-12',radius:20},
  {name:"Gear 16",count:16,class:'gear-16',radius:25.5},
  {name:"Gear 20",count:20,class:'gear-20',radius:31},
  {name:"Gear 24",count:24,class:'gear-24',radius:37.5},
  {name:"Diferential 28",count:28,class:'gear-28',radius:44},
  {name:"Gear 36",count:36,class:'gear-36',radius:30},
  {name:"Gear 40",count:40,class:'gear-40',radius:20},
  {name:"Truntable 56",count:56,class:'gear-56',radius:30},
   {name:"WormGear",count:1,class:'gear-worm',radius:13}
];

const motorcollection = [
            
        
  {name:"2986 (9V)",class:"motor-2986",volts:{
       0: {value:7,torque:1.28,rpm:16,current:0.04,mp:0.021,ep:0.36,e:16},
       1:{value:9,torque:1.28,rpm:16,current:0.04,mp:0.021,ep:0.36,e:16}
       }
   },
   {name:"EV3 large",class:"motor-ev3-large",volts:{
       0: {value:7,torque:17.3,rpm:78,current:0.69,mp:1.41,ep:5.17,e:27},
       1:{value:9,torque:17.3,rpm:105,current:0.69,mp:1.9,ep:6.21,e:31}
       }
   },
    {name:"NXT",class:"motor-nxt",volts:{
       0: {value:7,torque:16.7,rpm:82,current:0.55,mp:1.44,ep:3.85,e:37.3},
       1:{value:9,torque:16.7,rpm:117,current:0.55,mp:2.03,ep:4.95,e:41}
       }
   },
    {name:"PF XL",class:"motor-pf-xl",volts:{
       0: {value:7,torque:14.5,rpm:100,current:0.54,mp:1.51,ep:3.78,e:40},
       1:{value:9,torque:14.5,rpm:146,current:0.55,mp:2.21,ep:4.95,e:45}
       }
   },
   {name:"EV3-medium",class:"motor-ev3-medium",volts:{
       0: {value:7,torque:6.64,rpm:120,current:0.35,mp:0.83,ep:2.62,e:32},
       1:{value:9,torque:6.64,rpm:165,current:0.37,mp:1.15,ep:3.33,e:34}
       }
   },
   
  
    {name:"Boost Ext.",class:"motor-boost-ext",volts:{
       0: {value:7,torque:4.08,rpm:126,current:0.19,mp:0.54,ep:1.43,e:38},
       1:{value:9,torque:4.08,rpm:171,current:0.19,mp:0.73,ep:1.72,e:42}
       }
   },
   {name:"43362",class:"motor-71427",volts:{
      0: {value:7,torque:2.25,rpm:140,current:0.12,mp:0.33,ep:0.85,e:39},
       1:{value:9,torque:2.25,rpm:219,current:0.12,mp:0.51,ep:1.1,e:47}
       }
   },
   {name:"71427",class:"motor-71427",volts:{
       0: {value:7,torque:2.25,rpm:160,current:0.12,mp:0.38,ep:0.85,e:45},
       1:{value:9,torque:2.25,rpm:250,current:0.12,mp:0.58,ep:1.1,e:54}
       }
   },
    {name:"Boost Int.",class:"motor-boost-int",volts:{
       0: {value:7,torque:4.08,rpm:201,current:0.35,mp:0.86,ep:2.66,e:32},
       1:{value:9,torque:4.08,rpm:264,current:0.37,mp:1.13,ep:3.37,e:33}
       }
   },
     {name:"PUP medium",class:"motor-pup-medium",volts:{
       0: {value:7,torque:4.08,rpm:201,current:0.3,mp:0.86,ep:2.22,e:39},
       1:{value:9,torque:4.08,rpm:270,current:0.3,mp:1.15,ep:2.66,e:43}
       }
   },
     {name:"PF Medium",class:"motor-pf-medium",volts:{
       0: {value:7,torque:3.63,rpm:185,current:0.29,mp:0.70,ep:2.03,e:34},
       1:{value:9,torque:3.63,rpm:275,current:0.31,mp:1.04,ep:2.79,e:37}
       }
   },
    {name:"PF-large",class:"motor-pf-large",volts:{
       0: {value:7,torque:6.48,rpm:203,current:0.48,mp:1.38,ep:3.60,e:38},
       1:{value:9,torque:6.48,rpm:272,current:0.49,mp:1.85,ep:4.41,e:42}
       }
   },
    {name:"47154",class:"motor-47154",volts:{
       0: {value:7,torque:2.25,rpm:210,current:0.19,mp:0.49,ep:1.33,e:37},
       1:{value:9,torque:2.25,rpm:315,current:0.19,mp:0.74,ep:1.7,e:43}
       }
   },
     {name:"E-Motor",class:"motor-e-motor",volts:{
       0: {value:7,torque:1.32,rpm:300,current:0.17,mp:0.42,ep:1.27,e:33},
       1:{value:9,torque:1.32,rpm:420,current:0.18,mp:0.58,ep:1.62,e:36}
       }
   },
     {name:"5292",class:"motor-5292",volts:{
       0: {value:7,torque:5.7,rpm:580,current:1.37,mp:3.45,ep:10.3,e:34},
       1:{value:9,torque:5.7,rpm:780,current:1.40,mp:4.61,ep:12.6,e:37}
       }
   },
    {name:"PUP Train",class:"motor-pf-train",volts:{
       0: {value:7,torque:0.88,rpm:855,current:0.4,mp:0.79,ep:2.99,e:26},
       1:{value:9,torque:0.88,rpm:1242,current:0.41,mp:1.15,ep:3.73,e:31}
       }
   },
    {name:"PF Train",class:"motor-pf-train",volts:{
       0: {value:7,torque:0.9,rpm:1071,current:0.38,mp:0.99,ep:2.85,e:35},
       1:{value:9,torque:0.9,rpm:1250,current:0.38,mp:1.11,ep:3.42,e:33}
       }
   },
    {name:"2838",class:"motor-2838",volts:{
       0: {value:7,torque:0.45,rpm:1000,current:0.32,mp:0.46,ep:2.3,e:20},
       1:{value:9,torque:0.45,rpm:2000,current:0.32,mp:0.9,ep:3,e:31}
       }
   }
   
 
];



const  handleVoltageChange =(event:  React.MouseEvent<HTMLElement>, newValue: any )=>{
  if (newValue !== null) {
    setVoltageLock(newValue);
 }
}
const [motor, setMotor] = React.useState(motorcollection[0].name);

const [voltageLock, setVoltageLock] = useState(true as boolean);
const [selectedMotor, setSelectedMotor] = React.useState(motorcollection[0] as any);

const [gearSetList, setGearSetList] = React.useState([{
  left:{name:"Gear 16",count:16,class:'gear-16',radius:25.5},right:{name:"Gear 16",count:16,class:'gear-16',radius:25.5}}] as any);

const [output, setOutput] = React.useState({} as any);



useEffect(() =>{
  updategearsets([...gearSetList]);
},[selectedMotor,voltageLock]);



const selectmotor = function(event: SelectChangeEvent)
{
  setMotor(event.target.value)
  setSelectedMotor(  motorcollection.find(mc=>mc.name === event.target.value));
};




const addgearset = function(index:number)
{
  
   let gears = [...gearSetList];
   gears.splice(index+1, 0 ,{left:{name:"Gear 16",count:16,class:'gear-16',radius:25.5},right:{name:"Gear 16",count:16,class:'gear-16',radius:25.5}
  });
  updategearsets(gears);
 
};

const removegearset = function(index:number)
{
  let gears = [...gearSetList];
   gears.splice(index, 1 );
   
   updategearsets(gears);
};




const updategearsets = function(gears:any){
  console.info('update gears')
 
  let selectedMotorVolt =   selectedMotor.volts[voltageLock?0:1];
  gears.forEach((value : any, index: any) => {
  if(index === 0)
   {
       value.rpm = selectedMotorVolt?.rpm;
       value.torque = selectedMotorVolt?.torque;
        value.margin = 10;
        value.ratio = "-";
   }
   else
   {
       const prevgearset = gears[index-1];
       value.rpm= prevgearset.rpm / (prevgearset.right.count / prevgearset.left.count );
        value.torque= prevgearset.torque / (prevgearset.left.count / prevgearset.right.count  );
        value.ratio =  toDecimal(selectedMotorVolt?.rpm /Math.min( value.rpm,selectedMotorVolt?.rpm) )+"/"+ toDecimal(value.rpm/ Math.min(value.rpm,selectedMotorVolt?.rpm));
        value.margin = prevgearset.right.radius;
        
   }
   if(index == gears.length-1)
        {
            var rpmlast = value.rpm / (value.right.count / value.left.count );
            setOutput( {ratio: toDecimal(selectedMotorVolt?.rpm /Math.min( rpmlast,selectedMotorVolt?.rpm)) +"/"+ toDecimal(rpmlast/ Math.min(rpmlast,selectedMotorVolt?.rpm)), margin:value.right.radius, rpm:rpmlast,torque:value.torque / ( value.left.count /value.right.count)})
            
          }
       
   })
  setGearSetList(gears);
  
 
};

  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
  const [popoverInfo, setPopoverInfo]= React.useState({} as any);

  
  const handleClick = (event:any,side:string,gearset:any) => {
    setAnchorEl(event.currentTarget);
    const info = {side,gearset};
    setPopoverInfo(info);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  let side : string;
  let  gearset: any;
  const popoverOpen = Boolean(anchorEl);
  const popoverId = popoverOpen ? 'simple-popover' : undefined;

  const setGear = function(gear:any)
{
  if(popoverInfo.side ==='left')
  {
    popoverInfo.gearset.left = gear;
  }
  
  else
  {
    popoverInfo.gearset.right = gear;
  }
  updategearsets( [...gearSetList]);
  setAnchorEl(null);
};
function toDecimal(value:any)
{
  return typeof(value) === 'number'?value.toFixed(2):value;
}

  return (
    <div className='spe-container-top-gutter'>
     
    

     <Box sx={{display:"flex",columnGap:"2em",p: '1em'}}>
        <FormControl fullWidth size="small" sx={{width:'300px'}}>
        <InputLabel id="select-motor-label">{t("Motor")}</InputLabel>
        <Select
          labelId="select-motor-label"
          id="select-motor"
          value={motor}
          label={t("Motor")}
          onChange={selectmotor}
          
        >
           {motorcollection.map((m,i)=>
          <MenuItem  value={m.name} key={i.toString()}>
            <div  className={  m.class +' motor-seletor-li '}>
              <div className="motor-seletor-text-li">{m.name} </div> 
              <div className='motor-rpm'>RPM:{m.volts[1].rpm}</div>
              </div>
            </MenuItem>
           )}
        </Select>
      </FormControl>
         

      <FormGroup>
    <ToggleButtonGroup
      color="primary"
      value={voltageLock}
      exclusive
      onChange={handleVoltageChange}
      aria-label="Platform"
    >
      <ToggleButton value={true}>7,2V (NiMH)</ToggleButton>
      <ToggleButton value={false}>9V (Alkaline)</ToggleButton>
    
    </ToggleButtonGroup>
     
    </FormGroup>
</Box>

    <div className="containerbase">
       <div id="base" >
            <div style={{display:'flex',width:'100%',flexWrap: 'wrap'}}>
                <div className="gear-set-container"  >
                    <div className={selectedMotor.class}>

                    </div>
                </div>
                {gearSetList.map((gearset:any,index:number)=>
                <div key={index.toString()} className="gear-set-container">
                <div style={{marginLeft:index!=0?-gearset.margin:0}}  className={index%2?'clockwise gear-set-container':'counterclockwise gear-set-container'} >

                    <div className="axle"  style={{animationDuration:60 / gearset.rpm + 's','width':140+(gearset.left.radius+gearset.margin || 0)}}>
                        <div className="axle-text"><span style={{fontSize:'9px'}}>RPM</span> <br></br> <b>{toDecimal(gearset.rpm )}</b><br></br>
                          <span style={{fontSize:'9px'}}>{t("Torque").toUpperCase()}</span ><br></br><b>{toDecimal(gearset.torque)}</b><br></br>
                          <span style={{fontSize:'9px'}}>{t("Ratio").toUpperCase()}</span > <b>{gearset.ratio }</b> 
                        </div>
                    </div>
                    <div className="gear-set" style={{'marginLeft':-gearset.left.radius || 0}}>
                        <div style={{overflow:'hidden',position:'relative'}} >
                          <div onClick={(event)=>handleClick(event,'left',gearset)} className={gearset.left.count===1?'gear-left-worm':'gear gear-left'} style={{animationDuration:gearset.left.count!==1?60 / gearset.rpm  + 's':''}} >
                            <div className={gearset.left.class}  style={{animationDuration:gearset.left.count===1?60 / gearset.rpm * 5 + 's':''}} ></div>
                          </div> 
                        </div>   
                        <div style={{position:'relative'}}>
                          <div onClick={(event)=>handleClick(event,'right',gearset)} className="gear gear-right" style={{animationDuration:60 / (gearset.left.count /gearset.right.count)  / gearset.rpm + 's'}}>
                                  <div className={gearset.right.class}> </div>
                          </div>
                        </div>   
                        
                     

                      <IconButton className='btn-small-add-gear'  aria-label="delete" size="small"  onClick={()=>{addgearset(gearSetList.length)}} >
                        <AddCircleIcon fontSize="small" />
                      </IconButton>
                      <IconButton className='btn-small-remove-gear' aria-label="delete" size="small"  color='error' onClick={()=>{removegearset(index)}} >
                        <CancelIcon fontSize="small" />
                      </IconButton>
                      {/*
                        <button className="btn btn-success btn-xs btn-small-add-gear"  onClick={()=>{addgearset(index)}} >
                          <span  className="glyphicon glyphicon-plus">+</span>
                        </button>
                        <button ng-if="$index!==0" className="btn btn-danger btn-xs btn-small-remove-gear"  onClick={()=>{removegearset(index)}} >
                          <span  className="glyphicon glyphicon-remove">x</span>
                        </button>
                        */}
                        <div className="ratio"> {t("Ratio")} : <b>{toDecimal(gearset.left.count / Math.min(gearset.left.count,gearset.right.count)) }/{toDecimal(gearset.right.count / Math.min(gearset.left.count,gearset.right.count)) }</b>
                        </div>
                    </div>
                </div>
                {
                  gearSetList.length === index+1?
                  <div className='gear-set-container' >
                    <div  className={(gearSetList.length %2)?'gear-set-container clockwise':'gear-set-container counterclockwise'} style={{marginLeft:-output.margin || 0}}  >
                      <div className="axle"  style={{animationDuration :60 / output.rpm + 's',width:180 + (output.margin || 0)}}>
                        <div className="axle-text"><span style={{fontSize:'9px'}}>RPM</span> <br></br>  <b>{toDecimal(output.rpm ) }</b><br></br>
                        <span style={{fontSize:'9px'}}>{t("Torque").toUpperCase()}</span > <br></br> <b>{toDecimal(output.torque)}</b><br></br>
                          <span style={{fontSize:'9px'}}>{t("Ratio").toUpperCase()}</span > <b>{output.ratio }</b>
                        </div>
                      </div> 
                    </div>
                  <div className="gear-set-container " style={{marginLeft:-output.margin || 0}} >
                    <div className='gear-last-add'> 
                    
                      <IconButton aria-label="delete" size="large"  onClick={()=>{addgearset(gearSetList.length)}} >
  <AddCircleOutlineIcon fontSize="inherit" />
</IconButton>
                    </div>
                  </div>
                </div>
                :''
                }
               

            </div>

                )};
          </div>
        </div>
     </div>
     <Popover
        id={popoverId}
        open={popoverOpen}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <div style={{maxWidth:'170px' ,padding: '10px',display: 'inline-block'}}>
            
              {gearcollection.filter((a)=>{return !(popoverInfo.side === 'right' && a.name === 'WormGear') }).map((gear:any,index:number)=>
                <div   key={index.toString()} className={"gearselector "+ gear.class} onClick={()=>{setGear(gear)}} ></div>
                )}
           
           
        </div>
      </Popover>
    </div>

    
  );
}

export default Gears;
